import React, {
    useEffect,
    useRef, useState
} from "react";
import Input from "../../components/ui/formElements/Input";
import Button from "../../components/ui/buttons/Button";
import {
    useNavigate, useSearchParams
} from "react-router-dom";
import InputPass from "../../components/ui/formElements/InputPass";
import api from "../../api";
import { useDispatch } from "react-redux";
import { setToken } from "../../redux/slices/applicationSlice.js";
import { getUserFetch } from "../../redux/thunks/applicationThunks.js";
import { toast } from "react-toastify";
import DatePicker from "../../components/ui/formElements/DatePicker";
// import DatePicker from "react-datepicker";
import { ReactComponent as ChevronPrevIcon } from "../../assets/img/ico-chevron--prev.svg";
import { toBase64 } from "../../helpers/utils";

const InstructorSingUp = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentStep, setCurrentStep] = useState(1);
    const test = false;
    const [data, setData] = useState(test
        ? {
            fullName: "Test Instructor",
            email: "xataxe8317@nweal.com",
            phone: "+321456789123",
            birthday: new Date().toISOString(),
            password: "xataxe8317@nweal.com",
            photo: null
        }
        : {
            fullName: "",
            email: "",
            phone: "",
            birthday: new Date().toISOString(),
            password: "",
            photo: null
        });
    const [photo64, setPhoto64] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState("");
    const steps = [
        {
            title: "WELCOME TO Plan Seminars",
            description: "Let’s start, tell us a bit about yourself",
            bullet: true
        }, {
            title: "when’s your birthday",
            description: "Tell us how old are you",
            bullet: true
        }, {
            title: "Set your password",
            description: "Create password for your account",
            bullet: true
        }
    ];

    const stepsHandler = () => {
        if (currentStep === 1 && !data.fullName.length) {
            toast.error("FULL NAME is required");

            return;
        }

        if (currentStep === 2 && !data.email.length) {
            toast.error("EMAIL is required");

            return;
        }

        if (currentStep === 2 && !data.phone.length) {
            toast.error("PHONE is required");

            return;
        }

        if (currentStep < steps.length) {
            setCurrentStep((prev) => prev + 1);
        }
    };

    const changePhoto = async (file) => {
        setData({
            ...data,
            photo: file
        });
        const res = await toBase64(file);

        setPhoto64(res);
    };

    const signUp = async () => {
        if (data.password !== confirmPassword) {
            toast.error("\"Confirm Password\" is not equal to \"Password\"");

            return;
        }
        
        const formData = new FormData();

        Object.entries(data).forEach((item) => {
            if (item[0] === "birthday") {
                formData.append(item[0], new Date(item[1]).toISOString());
            } else {
                formData.append(item[0], item[1]);
            }
        });
        const response = await api.auth.signupInstructor(formData);

        if (response?.success) {
            if (response?.token) {
                localStorage.setItem("authToken", response.token);
                dispatch(setToken(response.token));
                dispatch(getUserFetch(response.token));
                navigate("/settings/my-profile");
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
        } else {
            toast.error(response.message || "Something went wrong");
        }
    };

    const handleBack = () => {
        if (currentStep >= 2) {
            setCurrentStep((prev) => prev - 1);
        }
    };

    const [params] = useSearchParams();

    const setDataFromSocial = async () => {
        let file = null
        const photoUrl = params.get("image");

        if (photoUrl) {
            try {
                const response = await fetch(photoUrl);

                if (!response.ok) {
                    throw new Error("Failed to fetch the image.");
                }

                const blob = await response.blob();

                file = new File([blob], (params.get("first_name") || "avatar") + "." + (typeof blob.type === "string" ? blob.type.split("/")?.[1] : "png"), { type: blob.type })
                changePhoto(file)
            } catch (error) {
                console.error("Error fetching image:", error);
            }
        }

        const firstNameFromParams = params.get("first_name") || '';
        const lastNameFromParams = params.get("last_name") || '';
        
        setData(prev => ({
            ...prev,
            email: params.get("email") || '',
            fullName: firstNameFromParams + firstNameFromParams ? " " : ''  + lastNameFromParams,
            photo: file
        }));
    };

    useEffect(() => {
        setDataFromSocial();
    }, []);

    return (
        <div className="container">
            {steps[currentStep - 1].bullet && (
                <div className="steps mb-36">
                    {steps.map((item, index) => item.bullet && (
                        <button
                            className={`steps__item ${index + 1 === currentStep
                                ? "active"
                                : ""}`}
                            key={index}
                            onClick={() => setCurrentStep(index + 1)}
                        />
                    ))}
                </div>
            )}

            {steps.map((item, index) => (
                <React.Fragment key={index}>
                    {index + 1 === currentStep && (
                        <div className="heading-block text-center mb-36">
                            <h3 className="heading">
                                {item.title}
                            </h3>

                            <p className="desk-heading">
                                {item.description}
                            </p>
                        </div>
                    )}
                </React.Fragment>
            )



            )}

            <form className="form form--md">
                {currentStep === 1 && (
                    <>
                        <label className="form-group input--file">
                            <input
                                accept={"image/*"}
                                id="fileInput"
                                onChange={(e) => {
                                    if (e.target.files[0]) {
                                        changePhoto(e.target.files[0]);
                                    }
                                }}
                                type="file"
                            />

                            <div className="preview overflow-hidden">
                                {
                                    data.photo && photo64
                                        ? (
                                            <img
                                                src={photo64}
                                                alt=""
                                            />
                                        )
                                        : (
                                            <span className="ico ico--sm">
                                                <svg
                                                    fill="none"
                                                    height="32"
                                                    viewBox="0 0 32 32"
                                                    width="32"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g clipPath="url(#clip0_100_11347)">
                                                        <path
                                                            d="M16 13V21"
                                                            stroke="#67676C"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1.4"
                                                        />

                                                        <path
                                                            d="M12 17H20"
                                                            stroke="#67676C"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1.4"
                                                        />

                                                        <path
                                                            d="M10 8L11.7037 5.445C11.795 5.30819 11.9186 5.196 12.0636 5.11838C12.2086 5.04076 12.3705 5.0001 12.535 5H19.465C19.6295 5.0001 19.7914 5.04076 19.9364 5.11838C20.0814 5.196 20.205 5.30819 20.2962 5.445L22 8H26C26.5304 8 27.0391 8.21071 27.4142 8.58579C27.7893 8.96086 28 9.46957 28 10V24C28 24.5304 27.7893 25.0391 27.4142 25.4142C27.0391 25.7893 26.5304 26 26 26H6C5.46957 26 4.96086 25.7893 4.58579 25.4142C4.21071 25.0391 4 24.5304 4 24V10C4 9.46957 4.21071 8.96086 4.58579 8.58579C4.96086 8.21071 5.46957 8 6 8H10Z"
                                                            stroke="#67676C"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1.4"
                                                        />
                                                    </g>

                                                    <defs>
                                                        <clipPath id="clip0_100_11347">
                                                            <rect
                                                                fill="white"
                                                                height="32"
                                                                width="32"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                        )
                                }
                            </div>

                            <p className="label">
                                Add a photo
                            </p>
                        </label>

                        <Input
                            inputClasses="input--solid"
                            label="Full Name"
                            onInput={(e) => {
                                setData({
                                    ...data,
                                    fullName: e.target.value
                                });
                            }}
                            placeholder="e.g. Jane Smith, Wade Warren..."
                            value={data.fullName}
                            wrapperClasses="form-group input--lg"
                        />

                        <Input
                            inputClasses="input--solid"
                            label="Email"
                            onInput={(e) => {
                                setData({
                                    ...data,
                                    email: e.target.value
                                });
                            }}
                            placeholder="Enter your email..."
                            value={data.email}
                            wrapperClasses="form-group input--lg"
                        />

                        <Input
                            inputClasses="input--solid"
                            label="Phone"
                            onInput={(e) => {
                                setData({
                                    ...data,
                                    phone: e.target.value
                                });
                            }}
                            placeholder="Enter your phone..."
                            value={data.phone}
                            wrapperClasses="form-group input--lg"
                        />
                    </>
                )}

                {currentStep === 2 && (
                    <div className="row">
                        <div className="col-md-4 mt-4">
                            {/* <Input
                                    wrapperClasses={'form-group input--lg'}
                                    inputClasses={'input--solid'}
                                    label={'Day'}
                                    placeholder={'11'}
                                /> */}
                            <div className="form-group form-group input--lg">
                                <label className="label">
                                    Day
                                </label>

                                <div className="day-datepicker">
                                    <DatePicker
                                        className="input input--solid"
                                        dateFormat="dd"
                                        onChange={(date) => {
                                            setData({
                                                ...data,
                                                birthday: date
                                            });
                                        }}
                                        selected={data.birthday}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-4">
                            <div className="form-group form-group input--lg">
                                <label className="label">
                                    Month
                                </label>

                                <div className="month-datepicker">
                                    <DatePicker
                                        className="input input--solid"
                                        dateFormat="MM"
                                        onChange={(date) => {
                                            setData({
                                                ...data,
                                                birthday: date
                                            });
                                        }}
                                        selected={data.birthday}
                                        showMonthYearPicker
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-4">
                            <div className="form-group form-group input--lg">
                                <label className="label">
                                    Year
                                </label>

                                <div className="month-datepicker">
                                    <DatePicker
                                        className="input input--solid"
                                        dateFormat="yyyy"
                                        onChange={(date) => {
                                            setData({
                                                ...data,
                                                birthday: date
                                            });
                                        }}
                                        selected={data.birthday}
                                        showYearPicker
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {currentStep === 3 && (
                    <>

                        <InputPass
                            forgotPass={false}
                            formGroupClass="input--lg input--icon-right"
                            inputClass="input--solid w-full"
                            label="Password"
                            onInput={(e) => {
                                setData({
                                    ...data,
                                    password: e.target.value
                                });
                            }}
                            placeholder="Password"
                            value={data.password}
                        />

                        <InputPass
                            forgotPass={false}
                            formGroupClass="input--lg input--icon-right"
                            inputClass="input--solid w-full"
                            label="Confirm Password"
                            onInput={(e) => {
                                setConfirmPassword(e.target.value);
                            }}
                            placeholder="Confirm Password"
                            value={confirmPassword}
                        />
                    </>
                )}

                <div className="row-group gap--xs">
                    {currentStep >= 2 && (
                        <Button
                            className="btn btn--default btn--lg w-full fs-20 mt-12"
                            onClick={handleBack}
                        >
                            <span className="ico ico-18">
                                <ChevronPrevIcon />
                            </span>

                            <span>
                                Back
                            </span>
                        </Button>
                    )}

                    {currentStep === steps.length
                        ? (
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    signUp();
                                }}
                                className="btn btn--primary btn--lg w-full fs-20 mt-12"
                            >
                                <span className="info">
                                    Create Account
                                </span>
                            </Button>
                        )


                        : (
                            <Button
                                className="btn btn--primary btn--lg w-full fs-20 mt-12"
                                onClick={stepsHandler}
                            >
                                <span className="info">
                                    Continue
                                </span>

                                <span className="ico">
                                    <svg
                                        fill="none"
                                        height="18"
                                        viewBox="0 0 19 18"
                                        width="19"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.25 3.375L12.875 9L7.25 14.625"
                                            stroke="currentColor"
                                            strokeLinecap="square"
                                            strokeWidth="2"
                                        />
                                    </svg>
                                </span>
                            </Button>
                        )}
                </div>
            </form>
        </div>
    );
};

export default InstructorSingUp;
