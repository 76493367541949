import React, { useState } from 'react'
import InviteInstructorModal from '../../../components/modals/InviteInstructorModal'
import TooltipInfo from '../../../components/parts/TooltipInfo'
import { explanations } from '../../../constants/explanations'
import { getImageSrc } from '../../../helpers/utils'

import { ReactComponent as RemoveIcon } from '../../../assets/img/remove-icon.svg'
import { ReactComponent as NextIcon } from '../../../assets/img/ico-chevron--next.svg'

const InviteInstructors = ({ onChange, preview, onPreviewChange }) => {
    const [inviteInstructorModalOpen, setInviteInstructorModalOpen] = useState(false);
    const [instructors, setInstructors] = useState(preview?.instructors);

    const handleAddInstructors = (data) => {
        setInstructors(data);

        onChange("instructors", data.map((item) => item?.user?.id));
        setInviteInstructorModalOpen(false);
        onPreviewChange("instructors", data);
    };

    const handleRemoveInstructor = (index) => {
        const updatedInstructors = instructors.filter((_, i) => i !== index);

        onChange("instructors", updatedInstructors.map((item) => item?.user?.id));
        setInstructors(updatedInstructors);
        onPreviewChange("instructors", updatedInstructors);
    };

    return (
        <div className="col-12">
            <hr className="hr-horisontal mb-42" />

            <div className="mb-42">
                <div className="row-group mb-32">
                    <h6 className="heading">
                        Invite Additional Instructor {" "}

                        <span className="color-grey">
                            (optional)
                        </span>
                    </h6>
                    <TooltipInfo content={explanations.inviteInstructor} />
                </div>
                {!!instructors?.length && (
                    <ul className="invite-instructors-list mb-32">
                        {
                            instructors.map((instructor, index) => (
                                <li
                                    key={index}
                                    className="invite-instructor__item"
                                >
                                    <div className="invite-instructor__img">
                                        <img
                                            src={getImageSrc(instructor.photo)}
                                            alt={instructor.fullName}
                                        />
                                    </div>

                                    <div className="invite-instructor__group-info">
                                        <span className="invite-instructor__name">
                                            {instructor.fullName}
                                        </span>

                                        <span className="invite-instructor__email color-grey fs-14">
                                            {instructor?.user?.email}
                                        </span>
                                    </div>

                                    <button
                                        onClick={() => handleRemoveInstructor(index)}
                                        className="btn"
                                    >
                                        <RemoveIcon />
                                    </button>
                                </li>
                            ))}
                    </ul>
                )}

                <button
                    className="btn btn--sm btn--primary"
                    data-modal="#invite-instructors"
                    onClick={() => setInviteInstructorModalOpen(true)}
                    type="button"
                >
                    <span className="info">
                        Send Invite
                    </span>

                    <span className="ico">
                        <NextIcon />
                    </span>
                </button>
            </div>
            {inviteInstructorModalOpen && (
                <InviteInstructorModal
                    open={inviteInstructorModalOpen}
                    handleClose={() => setInviteInstructorModalOpen(false)}
                    onSubmit={handleAddInstructors}
                    data={instructors}
                />
            )}
        </div>
    )
}

export default InviteInstructors