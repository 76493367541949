import React, { useState } from "react";
import Input from "../../../components/ui/formElements/Input";
import Button from "../../../components/ui/buttons/Button";
import SelectOne from "../../../components/ui/formElements/SelectOne";
import api from "../../../api";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { Country } from "country-state-city";
import { toBase64 } from "../../../helpers/utils";

const StudentMyProfile = ({ userData }) => {
    const [data, setData] = useState({
        email: userData.email,
        phone: userData.phone,
        ...userData.profile
    });
    const [updateData, setUpdateData] = useState({});
    const [images64, setImages64] = useState({
        photo: null,
        cover: null
    });
    const allCountries = Country.getAllCountries();
    const countries = allCountries?.map((item) => ({
        value: item.isoCode,
        label: item.name
    })) || [];
    const bely = [
        {
            value: "Purple Belt",
            label: "Purple Belt"
        }
    ];

    // const user = useSelector(getUser)

    // const [formData, setFormData] = useState({
    //     'fullName': '',
    //     'country': '',
    //     'birthday': '',
    //     'phone': '',
    //     'bely': '',
    //     'gym': '',
    // })
    // const [errors, setErrors] = useState({}) 

    // useEffect(() => {
    //     console.log(user)
    //     setFormData(prev => ({
    //         ...prev,
    //         fullName: user.profile.fullName,
    //         phone: user.phone,
    //         country: user.profile.country,
    //         gym: user.profile.gym,
    //         bely: user.profile.bely,
    //         birthday: user.profile.birthday,
    //     }))
    // }, []) 

    // const save = async (e) => {
    //     let localErr = false
    //     Object.entries(formData).forEach(([key, value]) => {
    //         if (!value) {
    //             localErr = true
    //             setErrors(prevState => ({ ...prevState, [key]: true }))
    //         }
    //     }) 

    //     if (localErr) return

    // const res = await api.profiles.updateStudent({})
    // } 

    const beltOptions = [
        "White Belt",
        "Blue Belt",
        "Purple Belt",
        "Brown Belt",
        "Black Belt",
        "Red/Black Belt (Coral Belt)",
        "Red/White Belt (Coral Belt)",
        "Red Belt"
    ].map((item) => ({
        value: item,
        label: item
    }));

    const onChangeData = (field, value) => {
        setData((prev) => {
            return {
                ...prev,
                [field]: value
            };
        });
        setUpdateData((prev) => {
            return {
                ...prev,
                [field]: value
            };
        });
    };

    const changePhoto = async (file, field) => {
        onChangeData(field, file);
        const res = await toBase64(file);

        setImages64((prev) => {
            return {
                ...prev,
                [field]: res
            };
        });
    };

    const save = async () => {
        if (updateData?.email || updateData?.phone) {
            const updatePayload = {};

            ["email", "phone"].forEach((item) => {
                if (updateData[item]) {
                    updatePayload[item] = updateData[item];
                }
            });
            const updateUserRes = await api.users.updateUser(updatePayload);

            if (updateUserRes.success) {
                toast.success(updateUserRes.message || "Updated");
            } else {
                toast.error(updateUserRes?.message || "Error");
            }
        }

        if (updateData?.country || updateData?.photo || updateData?.birthday || updateData?.fullName || updateData?.bely || updateData?.gym) {
            const formData = new FormData();

            if (updateData?.birthday) {
                formData.append("birthday", new Date(updateData?.birthday).toISOString());
            }

            [
                "country",
                "photo",
                "fullName",
                "bely",
                "gym"
            ].forEach((item) => {
                if (updateData[item]) {
                    formData.append(item, updateData[item]);
                }
            });
            const updateInstructorRes = await api.profiles.updateStudent(formData);

            if (updateInstructorRes.success) {
                toast.success(updateInstructorRes.message || "Updated");
                setUpdateData({});
            } else {
                toast.error(updateInstructorRes?.message || "Error");
            }
        }
    };

    return (
        <div className="settings-body settings-gap">
            <div className="sidebar-header heading-row">
                <h3 className="heading">
                    My Profile
                </h3>
            </div>

            <form
                className="form form-settings"
                method="post"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <div className="form-header">
                    <div className="row gutters-form">
                        <div className="col-12">
                            <div className="upload-photo">
                                <div className="photo-load">
                                    {/* <img src={require("../../../assets/img/user-photo.svg").default} /> */}
                                    {
                                        data?.photo
                                            ? (
                                                <img
                                                    src={images64?.photo
                                                        ? images64?.photo
                                                        : process.env.REACT_APP_API_URL + data?.photo}
                                                    alt=""
                                                />
                                            )


                                            : null
                                    }
                                </div>

                                <label className="upload-control">
                                    <input
                                        hidden={true}
                                        onChange={(e) => {
                                            changePhoto(e.target.files[0], "photo");
                                        }}
                                        type="file"
                                    />

                                    <div className="btn btn--primary btn--sm radius">
                                        <span>
                                            Upload New Picture
                                        </span>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-body">
                    <div className="form-items-list">
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        label="Full Name"
                                        onInput={(e) => {
                                            onChangeData("fullName", e.target.value);
                                        }}
                                        value={data?.fullName}
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <SelectOne
                                        label="Country"
                                        onChange={(e) => {
                                            onChangeData("country", e.value);
                                        }}
                                        options={countries}
                                        value={countries?.find((item) => item?.value === data.country) || {
                                            value: "US",
                                            label: "United States"
                                        }}
                                        wrapperClasses="select--outline select--outline-bg input--lg"
                                    />
                                </div>

                                <div className="col-12">
                                    <div className="form-group input--lg">
                                        <label className="label">
                                            Date of Birth
                                        </label>

                                        <div className="row gutters-form-2">
                                            <div className="col-md-4 col-sm-4 col-12">
                                                {/* <SelectOne
                                                        options={days}
                                                        wrapperClasses={'select--outline select--outline-bg input--lg'}
                                                        value={days[0]}
                                                    /> */}
                                                <div className="day-datepicker">
                                                    <DatePicker
                                                        className="input input--solid"
                                                        dateFormat="dd"
                                                        onChange={(date) => {
                                                            onChangeData("birthday", date);
                                                        }}
                                                        selected={data.birthday}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-sm-4 col-12">
                                                {/* <SelectOne
                                                        options={months}
                                                        wrapperClasses={'select--outline select--outline-bg input--lg'}
                                                        value={months[0]}
                                                    /> */}
                                                <div className="month-datepicker">
                                                    <DatePicker
                                                        className="input input--solid"
                                                        dateFormat="MM"
                                                        onChange={(date) => {
                                                            onChangeData("birthday", date);
                                                        }}
                                                        selected={data.birthday}
                                                        showMonthYearPicker
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-sm-4 col-12">
                                                {/* <SelectOne
                                                        options={yesrs}
                                                        wrapperClasses={'select--outline select--outline-bg input--lg'}
                                                        value={yesrs[0]}
                                                    /> */}
                                                <div className="month-datepicker">
                                                    <DatePicker
                                                        className="input input--solid"
                                                        dateFormat="yyyy"
                                                        onChange={(date) => {
                                                            onChangeData("birthday", date);
                                                        }}
                                                        selected={data.birthday}
                                                        showYearPicker
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <SelectOne
                                        label="Belt"
                                        onChange={(e) => {
                                            onChangeData("bely", e.value);
                                        }}
                                        options={beltOptions}
                                        value={beltOptions?.find((item) => item?.value === data.bely) || { }}
                                        wrapperClasses="select--outline  select--outline-bg input--lg"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        label="Gym"
                                        value="Arte Suave"
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        label="Email"
                                        onInput={(e) => {
                                            onChangeData("email", e.target.value);
                                        }}
                                        value={data.email}
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        label="Phone"
                                        onInput={(e) => {
                                            onChangeData("phone", e.target.value);
                                        }}
                                        value={data.phone}
                                        wrapperClasses="input--lg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-footer">
                    <div className="flex-group justify-end">
                        <Button
                            className={`btn btn--primary btn--sm ${!Object.entries(updateData).length
                                ? "disabled"
                                : ""}`}
                            onClick={save}
                        >
                            <span>
                                Save Changes
                            </span>
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default StudentMyProfile;
