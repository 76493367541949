import ModalTemplate from "./ModalTemplate";
import {
    useLocation, useNavigate
} from "react-router-dom";
import { ReactComponent as CheckIcon } from "../../assets/img/ico-check-editor.svg";
import Template from "../../pages/editor/templates/Template";
import { templates } from "../../pages/editor/templates/variants";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentTemplate } from "../../redux/slices/editorSlice";

const SelectTemplateModal = ({ open, handleClose, isNew }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const handleSubmit = () => {
        if (!selectedTemplate) return;

        dispatch(setCurrentTemplate(selectedTemplate));
        if(isNew) {
            navigate({
                search: 'editor=true'
            }); 
        } else {
            navigate("/template-editor", { state: { previousLocation: location.pathname } });
        }
        handleClose && handleClose();
    };

    return (
        <ModalTemplate
            modalSize="modal__dialog--xl"
            onClick={handleClose}
            open={open}
        >
            <div className="modal__body p-0">
                <form className="form">
                    <div className="form-header modal__header">
                        <div className="heading-block">
                            <h3 className="heading">
                                Select Cover Template
                            </h3>

                            <p className="text--md desk-heading">
                                Select one of ready cover templates to use
                            </p>
                        </div>

                        <button
                            className={`btn btn--primary btn--md gap-10 ${!selectedTemplate
                                ? "disabled"
                                : ""}`}
                            onClick={handleSubmit}
                            type="button"
                        >
                            <span className="info">
                                Submit Template
                            </span>

                            <span className="ico ico-18">
                                <svg
                                    fill="none"
                                    height="18"
                                    viewBox="0 0 19 18"
                                    width="19"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g>
                                        <path
                                            d="M7.25 3.375L12.875 9L7.25 14.625"
                                            stroke="currentColor"
                                            strokeLinecap="square"
                                            strokeWidth="2"
                                        />
                                    </g>

                                    <defs>
                                        <clipPath>
                                            <rect
                                                fill="white"
                                                height="18"
                                                transform="translate(0.5)"
                                                width="18"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </button>
                    </div>

                    <div className="form-body cards-templates-container modal__body scroll-custom">
                        <div className="row g-16 gutters-form-6">
                            {
                                templates.map((template) => (
                                    <div
                                        className="col-md-4 col-sm-6 col-12 flex justify-center"
                                        key={template.templateId}
                                        style={{
                                            aspectRatio: "1.32244897959",
                                            overflow: "hidden"
                                        }}
                                    >
                                        <label
                                            className="editor-checkbox"
                                            style={{ transform: "scale(0.46)" }}
                                        >
                                            <input
                                                type="radio"
                                                hidden
                                                className="hidden"
                                                name="template"
                                                checked={selectedTemplate?.templateId === template.templateId}
                                                onChange={() => setSelectedTemplate(template)}
                                            />

                                            <span className="ico ico-checked">
                                                <CheckIcon />
                                            </span>

                                            <div className="editor-checkbox--overlay" />

                                            <Template template={template} />
                                        </label>
                                    </div>
                                )



                                )
                            }
                        </div>
                    </div>
                </form>
            </div>
        </ModalTemplate >
    );
};

export default SelectTemplateModal;
