import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useParams
} from "react-router-dom";
import Button from "../../components/ui/buttons/Button";
import CheckboxSwitch from "../../components/ui/formElements/CheckboxSwitch";
import Input from "../../components/ui/formElements/Input";
import SelectOne from "../../components/ui/formElements/SelectOne";
import PageLoader from "../../components/ui/PageLoader";
import { belts } from "../../constants";
import {
    checkErrors, getDateRange, getFullLocation, getImageSrc
} from "../../helpers/utils";
import { getUser } from "../../redux/slices/applicationSlice";
import { getSeminarsById } from "../../redux/thunks/seminarsThunks";
import { ReactComponent as LocationIcon } from "../../assets/img/icon-decor-city.svg";
import SelectionImg from "../../assets/img/section.png";
import { Country } from "country-state-city";
import { trainingStyle as trainingStyles } from "../../constants";
import LoginForm from "../../components/parts/LoginForm";
import { buyTicketFetch } from "../../redux/thunks/ticketsThunks";
import { EventHelper } from "../../helpers/EventHelper";
import { EVENT_TYPE } from "../../constants/enums";
import { getSingleCampFetch } from "../../redux/thunks/planCampThuncks";

const BuySeminarPage = ({ type = EVENT_TYPE.seminar }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [agree, setAgree] = useState(true);
    const [isBuying, setIsBuying] = useState(false)

    const { id, chatId, requestId } = useParams();
    const user = useSelector(getUser);
    const { seminarDetails, isLoading: isSeminarsLoading } = useSelector((state) => state.seminars);
    const { campDetails, isLoading: isPlancampsLoading } = useSelector((state) => state.planCamps);
    const data = type === EVENT_TYPE.camp ? campDetails : seminarDetails;
    const isLoading = isPlancampsLoading || isSeminarsLoading;

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        belt: "",
        quantity: 1
    });
    const [errors, setErrors] = useState({});
    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const specificBeltOptions = belts.map(({ key, title }) => ({
        value: key,
        label: title
    }));

    const handleChange = (name, value) => {
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        setIsErrorsShown(true);
        console.log(errors);

        if (errors?.hasRequiredError) return;

        setIsBuying(true)
        const res = await dispatch(buyTicketFetch({
            eventId: id,
            eventType: "seminar",
            fullName: formData.fullName,
            email: formData.email,
            belt: formData.belt,
            quantity: formData.quantity
        })).unwrap()
        const paymentUrl = res?.data?.session?.url

        if (paymentUrl) {
            window.location.href = paymentUrl
        }

        setTimeout(() => setIsBuying(false), 100)
    };

    useEffect(() => {
        if (!id) return;
        if (type === EVENT_TYPE.camp) {
            dispatch(getSingleCampFetch(id));
        } else {
            dispatch(getSeminarsById(id));
        }
    }, [id, type]);

    useEffect(() => {
        setErrors(checkErrors(formData, {
            exceptions: [],
            requiredFields: ["fullName", "email", "belt", 'quantity']
        }));
    }, [formData]);


    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            fullName: user?.fullName || user?.profile?.fullName || "",
            email: user?.email || "",
            belt: user?.belt || ""
        }));
    }, [user]);

    if (isLoading) {
        return <PageLoader />;
    }

    if (!data) {
        return (
            <section className="section-42">
                <div className="container">
                    <h2 className="heading">
                        {type} Not found
                    </h2>
                </div>
            </section>

        );
    }

    return (
        <div className="section-with-image">
            <img src={SelectionImg} className="section-with-image__img" alt="section" />
            <div className="container">
                <div className="double-section full-height no-border flex">
                    <div className="double-section__content withBg pt-42">
                        {
                            user
                                ? (
                                    <>
                                        <div className="col-group gap--sm">
                                            <div className="pill pill--secondary">
                                                Limited tickets left
                                            </div>

                                            <h6 className="heading">
                                                {data.seminarName || data.name}
                                            </h6>

                                            <p className="text--xs color-text">
                                                {EventHelper.dateRange(data)}
                                            </p>
                                        </div>

                                        <hr className="hr-horisontal" />

                                        <div className="col-group gap-30">
                                            <h6 className="heading">
                                                {data.seminarName || data.name}
                                            </h6>

                                            <p className="color-text">
                                                {data.summary}
                                            </p>

                                            <div className="row-group gap-30">
                                                <div className="price-group fw-700 ttu">
                                                    <span className="price-group__price">
                                                        $

                                                        {data.minimalPriceTicket || 0}
                                                    </span>

                                                    {" "}

                                                    / ticket
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="hr-horisontal" />

                                        <div className="col-group gap-30">
                                            <p className="color-text text-sm">
                                                Ticket and receipt are sent to the info below:
                                            </p>

                                            <div className="row gx-16 gutters-form">
                                                <div className="col-md-6 col-sm-6 col-12">
                                                    <Input
                                                        error={isErrorsShown && errors.fullName}
                                                        inputClasses="input--solid"
                                                        label="Full Name"
                                                        onChange={(e) => handleChange("fullName", e.target.value)}
                                                        placeholder="Type your full name..."
                                                        type="text"
                                                        value={formData.fullName}
                                                        wrapperClasses="form-group flex-auto input--lg"
                                                    />
                                                </div>

                                                <div className="col-md-6 col-sm-6 col-12">
                                                    <Input
                                                        error={isErrorsShown && errors.email}
                                                        inputClasses="input--solid"
                                                        label="Email"
                                                        onChange={(e) => handleChange("email", e.target.value)}
                                                        placeholder="Type your email..."
                                                        type="text"
                                                        value={formData.email}
                                                        wrapperClasses="form-group flex-auto input--lg"
                                                    />
                                                </div>

                                                <div className="col-md-6 col-sm-6 col-12">
                                                    <Input
                                                        error={isErrorsShown && errors.quantity}
                                                        inputClasses="input--solid"
                                                        label="Quantity"
                                                        onChange={(e) => handleChange("quantity", e.target.value)}
                                                        type="number"
                                                        min={1}
                                                        value={formData.quantity}
                                                        wrapperClasses="form-group flex-auto input--lg"
                                                    />
                                                </div>

                                                <div className="col-md-6 col-sm-6 col-12">
                                                    <SelectOne
                                                        error={isErrorsShown && errors?.belt}
                                                        label="Belt Type"
                                                        onChange={(option) => handleChange("belt", option.value)}
                                                        options={specificBeltOptions}
                                                        placeholder="Select your belt"
                                                        value={formData.belt}
                                                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="hr-horisontal" />

                                        <div className="option-group">
                                            <CheckboxSwitch
                                                checked={agree}
                                                onChange={() => setAgree(!agree)}
                                                text="I want to receive newsletters"
                                            />
                                        </div>

                                        <div className="w-full text-center">
                                            <Button
                                                isLoading={isBuying}
                                                className="btn btn--primary btn--lg mb-20 w-full"
                                                onClick={handleSubmit}
                                            >
                                                <span className="info">
                                                    Continue
                                                </span>
                                            </Button>

                                            <p className="color-text text-14">
                                                When you click “Continue” you accept our

                                                {" "}

                                                <Link
                                                    className="color-secondary"
                                                    to="/terms"
                                                >
                                                    terms
                                                </Link>

                                                {" "}

                                                and

                                                {" "}

                                                <Link
                                                    className="color-secondary"
                                                    to="/privacy"
                                                >
                                                    privacy policy
                                                </Link>
                                            </p>
                                        </div>
                                    </>
                                )


                                : (
                                    <LoginForm
                                        isPreBooking
                                        wrapperClasses="form-login"
                                        description="Login or sign up to purchase on Global Seminars"
                                    />
                                )


                        }
                    </div>

                    <div className="double-section__aside withBackground">
                        <OrderSummary data={data} seminar={true} quantity={formData.quantity} />
                    </div>
                </div>
            </div>
        </div>
    );
};

function OrderSummary({ data, seminar = false, quantity = 1 }) {
    const traningStyle = trainingStyles.find(({ title }) => title?.toLowerCase() === data?.traningStyle?.toLowerCase())?.title;
    const cover = data?.photos?.find((photo) => photo.isCover || photo.id === data.coverId)?.url;
    const name = data?.seminarName || data?.name;

    return (
        <div className="card-shadow px-36 py-32 mb-16">
            <h6 className="heading mb-32">
                Order Summary
            </h6>

            <div className="row-group gap--xxl pb-24 border-bottom mb-24 flex-lg-nowrap flex-wrap">
                <div className="image-wrapper --medium-thumb">
                    <img
                        alt={name}
                        src={getImageSrc(cover)}
                    />
                </div>

                <div className="col-group gap--xxl justify-between">
                    {data.adress}

                    <div className="row-group color-secondary fw-500 text-13 gap-4">
                        <div className="ico">
                            <LocationIcon />
                        </div>

                        <span className="truncate">
                            {EventHelper.location(data)}
                        </span>
                    </div>

                    <div className="seminar-label-info">
                        <h6 className="heading">
                            {name}
                        </h6>
                    </div>
                </div>
            </div>

            <div className="ticket-info-body ticket-info-body--no-border mb-32">
                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Date:
                    </span>

                    <span className="fw-500">
                        {EventHelper.dateRange(data)}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Style:
                    </span>

                    <span className="fw-500">
                        {traningStyle}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Academy:
                    </span>

                    <span className="fw-500">
                        {data.userAcademy?.academyProfile?.name}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Instructors:
                    </span>

                    <span className="fw-500">
                        {data.instructors?.map((item) => item?.user?.instructorProfile?.fullName).join(", ")}
                    </span>
                </div>
            </div>

            {
                false && (
                    <Button className="btn--default btn--md w-full mb-32">
                        Apply Coupon
                    </Button>
                )}

            <hr className="hr-horisontal mb-32" />
            <div className="col-group gap--xs">
                <div className="row-group justify-between gap--md">
                    <div className="price-group">
                        <span className="color-grey mr-8">
                            Subtotal
                        </span>
                    </div>

                    <span className="price-group__price fs-20">
                        ${data.minimalPriceTicket * quantity}
                    </span>
                </div>
                {seminar &&
                    <div className="row-group justify-between gap--md">
                        <div className="price-group">
                            <span className="color-grey mr-8">
                                Service Fee
                            </span>
                        </div>

                        <span className="price-group__price fs-20">
                            ${EventHelper.fee(data) * quantity}
                        </span>
                    </div>
                }
                <div className="row-group justify-between gap--md">
                    <div className="price-group">
                        <span className="color-grey mr-8 fs-18">
                            Total Price
                        </span>

                        <span className="price-group__amount">
                            {EventHelper.ticketsLeft(data)} left
                        </span>
                    </div>

                    <span className="price-group__price fs-24">
                        ${EventHelper.totalTicketPrice(data) * quantity}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default BuySeminarPage;
