import React from "react";
import { getTeachStyle } from "../../../helpers/utils";
import { useTranslation } from "react-i18next";

const SinglePageTeachSyles = ({ teachingStyles }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    return (
        <div className="border-bottom mb-42 pb-24">
            <h4 className="heading mb-32">
                {t('teaching_styles')}
            </h4>

            <ul className="teaching-styles-list">
                {teachingStyles?.length && getTeachStyle(teachingStyles).map((item, index) => (
                    <li key={index}>
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SinglePageTeachSyles;
