import { useState } from "react";
import { Link } from "react-router-dom";

export const AccordionStep = ({ title, children, href }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div
                className="chat-sidebar__accordion gap--lg"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="ico">
                    <svg
                        fill="none"
                        height="22"
                        viewBox="0 0 22 22"
                        width="22"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_310_26441)">
                            <path
                                d="M3.4375 11H18.5625"
                                stroke="#BDBDBD"
                                strokeLinecap="square"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />

                            {
                                !isOpen && (
                                    <path
                                        d="M11 3.4375V18.5625"
                                        stroke="#BDBDBD"
                                        strokeLinecap="square"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                    />
                                )}
                        </g>

                        <defs>
                            <clipPath id="clip0_310_26441">
                                <rect
                                    fill="white"
                                    height="22"
                                    width="22"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </span>

                <h4 className="flex-auto">
                    {title}
                </h4>

                <Link
                    className="color-secondary"
                    to={href}
                >
                    Edit
                </Link>
            </div>

            {
                isOpen && (
                    <div
                        className={`col-group gap-24 pt-24 ${isOpen
                            ? "pb-24 border-bottom"
                            : ""}`}
                    >
                        {children}
                    </div>
                )}
        </>
    );
};
