import { Link } from 'react-router-dom';
import { ReactComponent as NextIcon } from '../../../assets/img/ico-chevron--next.svg'
import { PLANNING_STATUS } from '../constants/emuns';

const FooterActions = ({
    onSubmit,
    onCancel,
    showSubmitIcon,
    submitButtonText = 'Continue',
    chatId,
    isTerms,
}) => {

    return (
        <div className="mt-42">
            <div className="page-action-group mobile-actions">
                <button
                    className="btn btn--default btn--sm"
                    onClick={onCancel}
                    type='button'
                >
                    Cancel
                </button>
                <div className="row-group gap--xs">
                    {isTerms && (
                        <Link
                            className="btn btn--default btn--sm w-150"
                            to={`/chat/${chatId}?terms=true`}
                        >
                            Skip
                        </Link>
                    )}
                    <button
                        className="btn btn--primary btn--sm"
                        type='submit'
                        onClick={onSubmit}
                    >
                        <span className="info">{submitButtonText}</span>

                        {showSubmitIcon && (
                            <span className="ico">
                                <NextIcon />
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </div>

    );
};

export default FooterActions;
