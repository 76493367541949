import moment from "moment";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useParams
} from "react-router-dom";
import { toast } from "react-toastify";
import CheckboxSwitch from "../../../components/ui/formElements/CheckboxSwitch";
import PageLoader from "../../../components/ui/PageLoader";
import { EVENT_STEP } from "../../../constants/enums";
import { getImageSrc } from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import { getChatByIdFetch } from "../../../redux/thunks/chatsThunks";
import {
    acceptCampRequestFetch
} from "../../../redux/thunks/planCampThuncks";
import { AccordionStep } from "../components/AccordionStep";
import { CardDetails } from "../components/CardDetails";

const CampSummaryPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [agree, setAgree] = useState({
        booking: false,
        terms: false
    });
    const { campId, chatId, requestId } = useParams();
    const user = useSelector(getUser);
    // const { camps, campDetails, isLoading } = useSelector((state) => state.planCamps);
    const { currentChatData, isLoading } = useSelector((state) => state.chats);
    const campDetails = currentChatData?.plancamp

    useEffect(() => {
        if (!chatId) return;

        dispatch(getChatByIdFetch({ chatId }));
    }, [chatId]);

    const changeAgree = (type) => {
        setAgree((prev) => ({
            ...prev,
            [type]: !prev[type]
        }));
    };

    const handleSubmit = async () => {
        if (Object.values(agree).some((value) => !value)) {
            toast.warn("You must agree to the terms");

            return;
        }

        const res = await dispatch(acceptCampRequestFetch({
            plancampId: campDetails.id,
            requestId
        })).unwrap();

        if (res) {
            navigate(`/chat/${chatId}`);
        }
    };


    const preview = campDetails?.user?.role === USER_ROLE.academy
        ? `/planed-by-academy/camps/${campId}`
        : `/planed-by-insctructor/camps/${campId}`;

    return (
        <section className="section-42">
            <div className="container">
                {
                    !isLoading && campDetails
                        ? (
                            <div className="double-section flex gap-80">
                                <div className="double-section__content">
                                    <h3 className="heading">
                                        Camp Summary
                                    </h3>

                                    <div className="row-group gap--xxl">
                                        <div className="image-wrapper --small-thumb">
                                            <img
                                                alt={campDetails?.name}
                                                src={campDetails?.cover || getImageSrc(campDetails?.photos?.find((photo) => photo.isCover || photo.id === campDetails.coverId)?.url)}
                                            />
                                        </div>

                                        <div className="col-group flex-auto gap--lg justify-between">
                                            <p className="text--xs color-text">
                                                {`${moment(campDetails?.startAt).format("DD MMM, YYYY")} - ${moment(campDetails?.endAt).format("DD MMM, YYYY")}`}
                                            </p>

                                            <div className="seminar-label-info">
                                                <h6 className="heading">
                                                    {campDetails?.name}
                                                </h6>
                                            </div>
                                        </div>

                                        <Link
                                            className="btn btn--default btn--sm"
                                            to={preview}
                                        >
                                            Preview
                                        </Link>
                                    </div>

                                    <div className="col-group">
                                        <AccordionStep
                                            href={`./..?edit=${EVENT_STEP.date}`}
                                            title="Location & Date"
                                        >
                                            <div className="row gx-16 py-24 mb-10">
                                                <div className="col-lg-6">
                                                    <CardDetails label="Country">
                                                        <p>
                                                            {campDetails.country}
                                                        </p>
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-6">
                                                    <CardDetails label="Address">
                                                        <p>
                                                            {campDetails.address}
                                                        </p>
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-6">
                                                    <CardDetails label="Dates & time">
                                                        <div className="row-group justify-between">
                                                            <p className="fw-500">
                                                                Start
                                                            </p>

                                                            <p className="color-text">
                                                                {moment(campDetails.startAt).format("DD MMM, YYYY")}
                                                            </p>
                                                        </div>

                                                        <div className="row-group justify-between">
                                                            <p className="fw-500">
                                                                End
                                                            </p>

                                                            <p className="color-text">
                                                                {moment(campDetails.endAt).format("DD MMM, YYYY")}
                                                            </p>
                                                        </div>
                                                    </CardDetails>
                                                </div>
                                            </div>
                                        </AccordionStep>

                                        <AccordionStep
                                            href={`./../seminar-info?edit=${EVENT_STEP.details}`}
                                            title="Seminar Details"
                                        >
                                            <div className="row gx-16 py-24 mb-10">
                                                <div className="col-12">
                                                    <CardDetails label="Teaching Styles">
                                                        {campDetails.teachingStyles.map((item) => item)}
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label="Language">
                                                        {campDetails.languages?.map((item) => item)}
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label="Gi or NoGi">
                                                        {campDetails.traningStyle}
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label="Max Gym Capacity">
                                                        {campDetails.maxGymCapacity}
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label="Open to">
                                                        {campDetails.targetAudience}
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label="Under the Age of 18">
                                                        {campDetails.allowUnder18 ? "Allowed" : "Not Allowed"}
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label="Min Attendance">
                                                        {campDetails.minAttendance}
                                                    </CardDetails>
                                                </div>

                                                <div className="col-lg-4 col-md-6">
                                                    <CardDetails label="Min Attendance Deadline">
                                                        {campDetails.minAttendanceDeadline}
                                                    </CardDetails>
                                                </div>
                                            </div>
                                        </AccordionStep>

                                        <AccordionStep
                                            href={`./../design-text?edit=${EVENT_STEP.design}`}
                                            title="Design & Text"
                                        >
                                            {campDetails?.photos?.length && (
                                                <div className="row gutters-form-7">
                                                    {campDetails?.photos.map((photo) => (
                                                        <div
                                                            className="col-2"
                                                            key={photo?.id}
                                                        >
                                                            <img
                                                                className="w-full"
                                                                src={getImageSrc(photo?.url)}
                                                                style={{ height: 96 }}
                                                                alt={photo?.originalname}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            <CardDetails label="Camp Name">
                                                <p>{campDetails?.name}</p>
                                            </CardDetails>

                                            <CardDetails label="Summary">
                                                <p>{campDetails?.summary}</p>
                                            </CardDetails>
                                        </AccordionStep>

                                        <AccordionStep
                                            href={`./../booking?edit=${EVENT_STEP.terms}`}
                                            title="Booking Specific Terms"
                                        >
                                            <div className="terms-doc text--sm">
                                                {currentChatData?.academyAccept?.terms}
                                            </div>
                                        </AccordionStep>
                                    </div>

                                    <button
                                        className="btn btn--primary btn--lg"
                                        onClick={handleSubmit}
                                    >
                                        <span className="info">
                                            Confirm
                                        </span>
                                    </button>

                                    <div className="option-group mb-36">
                                        <CheckboxSwitch
                                            checked={agree.terms}

                                            onChange={() => changeAgree("terms")}
                                            text={(<>
                                                I have read and agreed to the&nbsp;
                                                <Link
                                                    className="color-secondary"
                                                    to="terms"
                                                >
                                                    Terms and Conditions
                                                </Link>
                                                &nbsp;and&nbsp;
                                                <Link
                                                    className="color-secondary"
                                                    to="privacy"
                                                >
                                                    Privacy Policy
                                                </Link>
                                            </>)}
                                        />

                                        <CheckboxSwitch
                                            checked={agree.booking}
                                            onChange={() => changeAgree("booking")}
                                            text="I accept the Booking Specific Terms (if included)"
                                        />
                                    </div>
                                </div>

                                <div className="double-section__aside">
                                    <div className="card-shadow px-36 py-32 mb-16">
                                        <div className="instructor-profile__group">
                                            <div className="instructor-profile__image-logo">
                                                <img
                                                    alt={user?.profile?.name}
                                                    className="ico ico--md rounded-full"
                                                    src={getImageSrc(user?.profile?.photo)}
                                                />
                                            </div>

                                            <div className="instructor-profile__group-info">
                                                <p className="instructor-profile__name">
                                                    {user?.profile?.name}
                                                </p>

                                                <p className="instructor-profile__location">
                                                    {user?.email}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-shadow px-36 py-32">
                                        <h6 className="heading mb-28">
                                            Price Information
                                        </h6>

                                        <div className="ticket-info-body ticket-info-body--no-border">
                                            <div className="ticket-info-body__item justify-between border-bottom pb-28 mb-28">
                                                {
                                                    campDetails?.sharedProfit ? (
                                                        <>
                                                            <span className="color-text">
                                                                Shared Profit:
                                                            </span>
                                                            <span className="fw-500">
                                                                {campDetails?.sharedProfit}

                                                                {" "}

                                                                /

                                                                {100 - campDetails?.sharedProfit}

                                                                %
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="color-text">
                                                                Ticket price:
                                                            </span>
                                                            <span className="fw-500">
                                                                ${campDetails?.minimalPriceTicket}
                                                            </span>
                                                        </>
                                                    )
                                                }
                                            </div>

                                            <div className="ticket-info-body__item justify-between">
                                                <span className="color-text">
                                                    Instructor Cut:
                                                </span>

                                                <span className="fw-500">
                                                    {
                                                        campDetails?.sharedProfit ? (
                                                            <span className="fw-500">
                                                                $ {campDetails?.minimalPriceTicket * campDetails?.minimumAttendance * campDetails?.sharedProfit / 100}
                                                            </span>
                                                        ) : (
                                                            <span className="fw-500">
                                                                $ {campDetails?.instructorPrice}
                                                            </span>
                                                        )
                                                    }
                                                </span>
                                            </div>

                                            <div className="ticket-info-body__item justify-between">
                                                <span className="color-text">
                                                    Academy Potential Revenue:
                                                </span>

                                                {
                                                    campDetails?.sharedProfit ? (
                                                        <span className="fw-500">
                                                            $ {(campDetails?.minimalPriceTicket * campDetails?.minimumAttendance) - (campDetails?.minimalPriceTicket * campDetails?.minimumAttendance * campDetails?.sharedProfit / 100)}
                                                        </span>
                                                    ) : (
                                                        <span className="fw-500">
                                                            $ {campDetails?.minimalPriceTicket * campDetails?.minimumAttendance - campDetails?.instructorPrice}
                                                        </span>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )


                        : isLoading
                            ? <PageLoader />
                            : (
                                <h2 className="heading">
                                    Seminar is not found
                                </h2>
                            )


                }
            </div>
        </section>
    );
};

export default CampSummaryPage;
