import { Route } from "react-router-dom";
import SecondaryLayout from "../layouts/SecondaryLayout";
import CampSummaryPage from "../pages/planning-new/camp/CampSummaryPage";
import EditCampPage from "../pages/planning-new/camp/EditCampPage";
import PlanCampPage from "../pages/planning-new/camp/PlanCampPage";
import EditSeminarPage from "../pages/planning-new/seminar/EditSeminarPage";
import PlanSeminarPage from "../pages/planning-new/seminar/PlanSeminarPage";
import SeminarSummaryPage from "../pages/planning-new/seminar/SeminarSummaryPage";
import EditTourPage from "../pages/planning-new/tour/EditTourPage";
import PlanTourPage from "../pages/planning-new/tour/PlanTourPage";
import PreviewTour from "../pages/planning/PreviewTour";

export const planningRoutes = (
    <Route element={<SecondaryLayout />}>
        {/* 
            Routes for planning a seminar
        */}
        <Route
            element={<PlanSeminarPage />}
            path="/plan-seminar/*"
        />
        {/* 
            Routes for editing a seminar
        */}
        <Route
            element={<SeminarSummaryPage />}
            path="/edit-seminar/:chatId?/:seminarId?/:requestId?/summary"
        />

        <Route
            element={<EditSeminarPage />}
            path="/edit-seminar/:chatId?/:seminarId?/:requestId?/*"
        />
        {/* 
            Routes for planning a camp
        */}
        <Route
            element={<PlanCampPage />}
            path="/plan-camp/*"
        />
        {/* 
            Routes for editing a camp
        */}
        <Route
            element={<CampSummaryPage />}
            path="/edit-camp/:chatId?/:campId?/:requestId?/summary"
        />
        <Route
            element={<EditCampPage />}
            path="/edit-camp/:chatId?/:campId?/:requestId?/*"
        />

        <Route
            element={<PlanTourPage />}
            path="/plan-tour"
        />
        <Route
            element={<EditTourPage />}
            path="/edit-tour/:tourId?"
        />

        <Route
            element={<PreviewTour />}
            path="/plan-tour/preview-tour/:tourId"
        />

    </Route>
);