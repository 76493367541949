import React, {
    useState, useEffect
} from "react";
import html2canvas from "html2canvas";
import ModalTemplate from "./ModalTemplate";
import Input from "../ui/formElements/Input";
import { toBlob } from "html-to-image";

const CreateSignatureModal = ({ open, handleClose, footerBorderTop = true, setSignature }) => {
    const [activeTab, setActiveTab] = useState("Type");
    const tabs = ["Type", "Draw"];
    const [typeString, setTypeString] = useState("John Doe");


    const selectedFontItem = (index) => {
        html2canvas(document.querySelector(`.sign-font-item-${index}`)).then(function (canvas) {
            // var myImage = canvas.toDataURL("img/png");
            // setSignature(myImage); 

            canvas.toBlob((blob) => {
                const file = new File([blob], "signature.jpg", { type: "image/jpeg" });

                setSignature(file);
            }, "image/jpeg");
            handleClose();
        });
    };

    const selectFileImage = async (e) => {
        // let resultBase64 = await toBase64(e.target.files[0]);
        // setSignature(resultBase64); 
        setSignature(e.target.files[0]);
        handleClose();
    };

    const initDrawer = () => {
        window.requestAnimFrame = (function (callback) {
            return window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.oRequestAnimationFrame ||
                window.msRequestAnimaitonFrame ||
                function (callback) {
                    window.setTimeout(callback, 1000 / 60);
                };
        }());
        const canvas = document.getElementById("sig-canvas");
        const ctx = canvas.getContext("2d");

        ctx.strokeStyle = "#222222";
        ctx.lineWidth = 4;
        let drawing = false;
        let mousePos = {
            x: 0,
            y: 0
        };
        let lastPos = mousePos;

        canvas.addEventListener("mousedown", function (e) {
            drawing = true;
            lastPos = getMousePos(canvas, e);
        }, false);
        canvas.addEventListener("mouseup", function (e) {
            drawing = false;
        }, false);
        canvas.addEventListener("mousemove", function (e) {
            mousePos = getMousePos(canvas, e);
        }, false);
        // Add touch event support for mobile
        canvas.addEventListener("touchstart", function (e) {
        }, false);
        canvas.addEventListener("touchmove", function (e) {
            const touch = e.touches[0];
            const me = new MouseEvent("mousemove", {
                clientX: touch.clientX,
                clientY: touch.clientY
            });

            canvas.dispatchEvent(me);
        }, false);
        canvas.addEventListener("touchstart", function (e) {
            mousePos = getTouchPos(canvas, e);
            const touch = e.touches[0];
            const me = new MouseEvent("mousedown", {
                clientX: touch.clientX,
                clientY: touch.clientY
            });

            canvas.dispatchEvent(me);
        }, false);
        canvas.addEventListener("touchend", function (e) {
            const me = new MouseEvent("mouseup", {});

            canvas.dispatchEvent(me);
        }, false);

        function getMousePos (canvasDom, mouseEvent) {
            const rect = canvasDom.getBoundingClientRect();

            return {
                x: mouseEvent.clientX - rect.left,
                y: mouseEvent.clientY - rect.top
            };
        }

        function getTouchPos (canvasDom, touchEvent) {
            const rect = canvasDom.getBoundingClientRect();

            return {
                x: touchEvent.touches[0].clientX - rect.left,
                y: touchEvent.touches[0].clientY - rect.top
            };
        }

        function renderCanvas () {
            if (drawing) {
                ctx.moveTo(lastPos.x, lastPos.y);
                ctx.lineTo(mousePos.x, mousePos.y);
                ctx.stroke();
                lastPos = mousePos;
            }
        }

        // Prevent scrolling when touching the canvas
        document.body.addEventListener("touchstart", function (e) {
            if (e.target == canvas) {
                e.preventDefault();
            }
        }, false);
        document.body.addEventListener("touchend", function (e) {
            if (e.target == canvas) {
                e.preventDefault();
            }
        }, false);
        document.body.addEventListener("touchmove", function (e) {
            if (e.target == canvas) {
                e.preventDefault();
            }
        }, false);

        (function drawLoop () {
            window.requestAnimFrame(drawLoop);
            renderCanvas();
        }());

        function clearCanvas () {
            canvas.width = canvas.width;
        }

        const clearBtn = document.getElementById("sig-clearBtn");
        const submitBtn = document.getElementById("sig-submitBtn");

        clearBtn.addEventListener("click", function (e) {
            clearCanvas();
        }, false);
        submitBtn.addEventListener("click", function (e) {
            // var dataUrl = canvas.toDataURL();
            // setSignature(dataUrl); 
            toBlob(canvas, { skipFonts: true }).then((blob) => {
                const file = new File([blob], "signature.jpg", { type: "image/jpeg" });

                setSignature(file);
            });
            // canvas.toBlob((blob) => {
            //     let file = new File([blob], "signature.jpg", { type: "image/jpeg" })
            //     setSignature(file);
            // }, 'image/jpeg'); 
            handleClose();
        }, false);
    };

    useEffect(() => {
        if (activeTab === "Draw") {
            initDrawer();
        }
    }, [activeTab]);

    return (
        <ModalTemplate
            modalSize="modal__dialog--md"
            onClick={handleClose}
            open={open}
        >
            <div className="modal__body">
                <div className="heading-block mb-24">
                    <h3 className="heading">
                        Create Signature
                    </h3>
                </div>

                <div className="row-group gap--xl">
                    {
                        tabs?.map((item, index) => {
                            return (
                                <a
                                    className={`btn btn--md ${activeTab === item
                                        ? "btn--primary"
                                        : ""}`}
                                    href="#"
                                    key={index}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setActiveTab(item);
                                    }}
                                >
                                    {item}
                                </a>
                            );
                        })
                    }

                    <label className="btn btn--md">
                        Image

                        <Input
                            accept="image/png, image/jpeg"
                            hidden
                            id=""
                            name=""
                            onChange={(e) => {
                                selectFileImage(e);
                            }}
                            type="file"
                        />
                    </label>
                </div>

                <div className="w-full pt-24">
                    {activeTab === "Type"
                        ? (
                            <div>
                                <div className="flex flex-col">
                                    <Input
                                        inputClasses="input--solid"
                                        onChange={(e) => {
                                            setTypeString(e.target.value);
                                        }}
                                        placeholder="John Doe"
                                        type="text"
                                        value={typeString}
                                        wrapperClasses="input--lg mb-24"
                                    />

                                    <div className="flex flex-wrap gap--md justify-center">
                                        {
                                            [
                                                "Gamja Flower",
                                                "Indie Flower",
                                                "Charmonman",
                                                "Pacifico",
                                                "Gloria Hallelujah",
                                                "Amatic SC",
                                                "Shadows Into Light",
                                                "Dancing Script",
                                                "Dokdo",
                                                "Permanent Marker",
                                                "Patrick Hand",
                                                "Courgette"
                                            ]?.map((font, index) => {
                                                return (
                                                    <div
                                                        className={`text--xl btn btn--lg sign-font-item-${index}`}
                                                        key={index}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            selectedFontItem(index);
                                                        }}
                                                        style={{ fontFamily: font + ", sans-serif" }}
                                                    >
                                                        {typeString}
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        )


                        : null}

                    {
                        activeTab === "Draw"
                            ? (
                                <div className="w-full flex flex-col pt-24">
                                    <canvas
                                        className="card-bordered mx-auto mb-24"
                                        height="160"
                                        id="sig-canvas"
                                        width={420}
                                    >
                                        Get a better browser.
                                    </canvas>

                                    <div className="row-group justify-between">
                                        <button
                                            className="btn btn--primary btn--md"
                                            id="sig-clearBtn"
                                        >
                                            Clear
                                        </button>

                                        <button
                                            className="btn btn--primary btn--md"
                                            id="sig-submitBtn"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            )


                            : null
                    }
                </div>
            </div>
        </ModalTemplate>
    );
};

export default CreateSignatureModal;
