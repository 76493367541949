export function getStartDate(dates) {
    return dates?.length
        ? dates[0]?.date
        : null;
}

export function calculateMinAttendanceDeadline(seminarDate, isInCountry) {
    const seminarDateObj = new Date(seminarDate);
    const deadlineOffset = isInCountry
        ? 7
        : 14

    seminarDateObj.setDate(seminarDateObj.getDate() - deadlineOffset);
    const minAttendanceDeadline = seminarDateObj.toISOString();

    return minAttendanceDeadline;
}