import classNames from "classnames";
import {
    useEffect, useMemo, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useSearchParams
} from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../api";
import ReschedulePrivateLessonModal from "../../components/modals/ReschedulePrivateLessonModal";
import { PaginationTemplate } from "../../components/parts/PaginationTemplate";
import TooltipInfo from "../../components/parts/TooltipInfo";
import Button from "../../components/ui/buttons/Button";
import Loader from "../../components/ui/Loader";
import { EVENT_TYPE, USER_ROLE } from "../../constants/enums";
import { EventHelper } from "../../helpers/EventHelper";
import { calcTotalHours, getDateRange, getImageSrc, getTrainingStyle } from "../../helpers/utils";
import { useTabs } from "../../hooks/useTabs";
import { getUser } from "../../redux/slices/applicationSlice";
import {
    acceptInstructorCampRequestFetch, rejectCampRequestFetch
} from "../../redux/thunks/planCampThuncks";
import { acceptRequestsPrivateLessonsFetch, rejectRequestsPrivateLessonFetch } from "../../redux/thunks/privateLessonsThunks";
import {
    acceptInstructorRequestFetch, cancelSeminarRequestFetch
} from "../../redux/thunks/seminarsThunks";

const initialCounts = {
    "upcomingSeminarsCount": 0,
    "upcomingPlancampsCount": 0,
    "plannedSeminars": 0,
    "plannedPlancamps": 0,
    "inProgressPlancampsCount": 0,
    "inProgressSeminarsCount": 0,
    "previousSeminars": 0,
    "previousPlancamps": 0,
    "newRequestsSeminarCount": 0,
    "newRequestsPlancampCount": 0,
    "seminarRequestsCount": 0,
    "plancampRequestsCount": 0,
    "countMyOwnEvents": 0,
    countMySeminarEvents: 0,
    countMyPlancampEvents: 0,
    countPrivateLessons: 0,
    academyHostRequestPlancampCount: 0,
    academyHostRequestSeminarCount: 0
}

export const InstructorsAndAcademiesRequests = ({ userRole }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const eventType = searchParams.get("eventType")
    const requestType = searchParams.get("requestType")

    const [requests, setRequests] = useState([]);
    const [pagination, setPagination] = useState({});
    const [isLoading, setIsLoading] = useState(false);


    const [counts, setCounts] = useState(initialCounts);

    const getCounts = async () => {
        const res = await api.requests.getCounts()
        if (res?.success) {
            setCounts(res.data)
        }
    }

    const getAllRequests = async (eventType, requestType) => {
        const isMyOwn = requestType === 'my' ? 'true' : '';

        const [requestsResponse, hostingResponse] = await Promise.all([
            api.requests.getRequests(`no_limit=true&event=${eventType}${isMyOwn ? `&is_my_own=${isMyOwn}` : ''}`),
            api.requests.getHostingRequests(`no_limit=true&type=${eventType}${isMyOwn ? `&is_my_own=${isMyOwn}` : ''}`)
        ]);

        if (requestsResponse?.success && hostingResponse?.success) {
            const requestsData = requestsResponse.data || [];
            const hostingData = hostingResponse.data?.map(item => ({ ...item, isHosting: true })) || [];

            return {
                data: [...requestsData, ...hostingData],
                success: true
            };
        }

        return {
            data: [],
            success: false
        };
    };

    const tabs = useMemo(() => [
        {
            label: "Requests",
            id: "requests",
            count: counts.seminarRequestsCount + counts.plancampRequestsCount + counts.countMySeminarEvents + counts.countMyPlancampEvents +
                counts.academyHostRequestPlancampCount + counts.academyHostRequestSeminarCount,
            fetcher: (eventType, requestType) => getAllRequests(eventType, requestType)
        },
        {
            label: "Invitations",
            id: "invitations",
            count: counts.countMySeminarEvents + counts.countMyPlancampEvents,
            fetcher: (eventType) => api.requests.getRequests(`is_my_own=true&event=${eventType}`)
        },
        // {
        //     label: "Seminar/Camps hosting",
        //     id: "hosting",
        //     count: counts.academyHostRequestPlancampCount + counts.academyHostRequestSeminarCount,
        //     fetcher: (eventType) => api.requests.getHostingRequests(`type=${eventType}`),
        // },
        {
            label: "Private Lesson Requests",
            id: "private",
            count: counts.countPrivateLessons,
            fetcher: api.requests.getRequestsPrivateLessons,
            acceptOnly: [USER_ROLE.instructor]
        },
    ], [counts])

    const { activeTab, setActiveTab, activeTabObject } = useTabs(tabs, "tab")

    const getRequests = async () => {
        if (!activeTabObject?.fetcher) return
        setIsLoading(true);
        getCounts()
        const res = await activeTabObject.fetcher(eventType, requestType);

        if (res?.success) {
            setRequests(res.data);
            setPagination(res.pagination)
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getRequests()
    }, [searchParams]);
    useEffect(() => {
        setSearchParams(prev => {
            prev.set('eventType', EVENT_TYPE.seminar)
            prev.set('requestType', 'incoming')
            return prev
        })
    }, [])

    const handleChangeTab = (tab) => {
        setRequests([])
        setActiveTab(tab);
    };

    const tabComponents = {
        requests: <RequestsList data={requests} update={getRequests} />,
        invitations: <RequestsList data={requests} update={getRequests} />,
        // hosting: <RequestsList data={requests} update={getRequests} isHosting />,
        private: <LessonRequestsList data={requests} update={getRequests} />,
    }

    const renderTab = () => {
        return tabComponents[activeTab]
    }

    return (
        <div className="tab-seminars tabs">
            <div className="tabs__header flex items-center border-bottom gap-24 pb-24 pt-24 mb-24">
                <div className="tabs__nav row-group gap-24 flex-auto">
                    {
                        tabs.filter(({ acceptOnly }) => !acceptOnly?.length || acceptOnly?.includes(userRole)).map(tab => (
                            <button
                                key={tab.id}
                                className={classNames("tab-link", { "active-tab": activeTab === tab.id })}
                                onClick={() => handleChangeTab(tab.id)}
                            >
                                <span className="tab-link__text">
                                    {tab.label}
                                </span>

                                <span className="tab-link__number">
                                    {tab.count}
                                </span>
                            </button>
                        ))
                    }
                </div>
            </div>

            <div>
                <div className="tab active-tab">
                    {activeTab === 'requests' && <OwnerTypeSwitcher />}
                    {activeTab !== 'private' && <EventTypeSwitcher />}
                    {
                        requests?.length && !isLoading
                            ? renderTab()
                            : isLoading
                                ? (<Loader />)
                                : (<h2>There is no requiests yet</h2>)
                    }
                    <PaginationTemplate {...pagination} />
                </div>
            </div>
        </div>
    );
};

function EventTypeSwitcher() {
    const tabs = [
        {
            label: "Seminars",
            id: EVENT_TYPE.seminar,
        },
        {
            label: "Camps",
            id: EVENT_TYPE.camp,
        },
    ]
    const { activeTab, setActiveTab } = useTabs(tabs, "eventType")

    return (
        <div className="flex items-center gap-24 mb-24 flex-auto flex-wrap select-checkers-wrapper">
            <div className="select-checkers">
                {tabs.map((item, index) => (
                    <Button
                        onClick={() => setActiveTab(item.id)}
                        key={index}
                        className={
                            classNames('btn btn--md radius',
                                { 'btn--default': item.id === activeTab })
                        }
                    >
                        <span>{item.label}</span>
                    </Button>
                ))}

            </div>
        </div>
    )
}
function OwnerTypeSwitcher() {
    const tabs = [
        {
            label: "Requests",
            id: 'incoming',
        },
        {
            label: "My Requests",
            id: 'my',
        },
    ]
    const { activeTab, setActiveTab } = useTabs(tabs, "requestType")

    return (
        <div className="flex items-center gap-24 mb-24 flex-auto flex-wrap select-checkers-wrapper">
            <div className="select-checkers">
                {tabs.map((item, index) => (
                    <Button
                        onClick={() => setActiveTab(item.id)}
                        key={index}
                        className={
                            classNames('btn btn--md radius btn--tab', {
                                'active': item.id === activeTab
                            })
                        }
                    >
                        <span>{item.label}</span>
                    </Button>
                ))}

            </div>
        </div>
    )
}
function RequestItem({ data, getRequests, isHosting }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const me = useSelector(getUser);
    const isSeminar = data.object_type === EVENT_TYPE.seminar;
    const isCamp = data.object_type === EVENT_TYPE.camp;

    const handleApprove = async () => {
        const requestId = data.id;
        if (!requestId) return;

        if (isHosting) {
            const res = await api.requests.acceptHostingRequests({ requestId });
            const chatId = res?.data?.chatId || res?.data?.chat?.id || res?.chat?.id;

            if (chatId) {
                navigate(`/chat/${chatId}`);
            }
        } else {
            const res = isSeminar
                ? await dispatch(acceptInstructorRequestFetch({ requestInstructorId: requestId })).unwrap()
                : await dispatch(acceptInstructorCampRequestFetch({ instructorId: requestId })).unwrap();

            const chatId = res?.data?.chatId || res?.data?.chat?.id || res?.chat?.id;

            if (chatId) {
                navigate(`/chat/${chatId}`);
            }

        }
        getRequests();
    };

    const handleCancelRequest = async () => {
        const requestId = data.id;
        if (!requestId) return;

        if (isHosting) {
            const res = await api.requests.declineHostingRequests({ requestId });
            if (res?.success) {
                toast.success(res?.message)
            }
        } else {
            if (isSeminar) {
                await dispatch(cancelSeminarRequestFetch({ requestId })).unwrap();
            } else if (isCamp) {
                await dispatch(rejectCampRequestFetch({ requestId })).unwrap();
            }
        }
        getRequests();
    };
    const user = data.owner;
    const inviteMessage = data.inviteMessage;
    const isMy = data?.owner?.id === me?.id;
    const event = data.seminar || data.plancamp;
    const hrefPrefix = event?.userAcademy
        ? "planed-by-academy"
        : "planed-by-insctructor";
    if (!event) return null
    return (
        <div className="request-item">
            <div className="request-item__user">
                <div className="ico ico--md rounded-full img-wrapper">
                    <img
                        alt={user?.fullName || user?.name}
                        src={getImageSrc(user?.photo)}
                    />
                </div>

                <div
                    className="col-group gap-4"
                    style={{ width: 224 }}
                >
                    <h6 className="heading truncate flex-auto">
                        {event?.name || event?.seminarName}
                    </h6>
                    <TooltipInfo content={inviteMessage}>
                        <p className="color-text line-clamp-2 flex-auto">
                            {isMy ? event?.summary : inviteMessage}
                        </p>
                    </TooltipInfo>
                </div>
                {!data?.isReaded && !isMy && <span className="badge badge--primary">New</span>}
            </div>

            <div className="request-item__details">
                <div className="col-group gap--xs">
                    <span className="label">
                        Price Range
                    </span>

                    <p className="fw-500 text--sm">
                        {EventHelper.priceRange(event)}
                    </p>
                </div>

                {/* <div className="col-group gap--xs">
                    <span className="label">
                        Ticket Price
                    </span>

                    <p className="fw-500 text--sm">
                        {event?.ticketPrice}
                    </p>
                </div> */}

                <div className="col-group gap--xs">
                    <span className="label">
                        teaching
                    </span>

                    <p className="fw-500 text--sm">
                        {EventHelper.style(event)?.title}
                    </p>
                </div>

                <div className="col-group gap--xs">
                    <span className="label">
                        Date
                    </span>

                    <p className="fw-500 text--sm">
                        {new Date(event.startAt || event.seminarDates?.[0]?.date).toLocaleDateString()}
                    </p>
                </div>
            </div>

            <div className="request-item__action">
                {/* <Button className="btn btn--sm btn--square notification" count={data.count}> */}
                {/* <Link to={`/chat/${data.chat?.id}`} className="btn btn--sm btn--square">
                    <span className="ico">
                        <ChatIcon />
                    </span>
                </Link> */}
                <Link
                    className="btn btn--sm"
                    to={`/${hrefPrefix}/${event?.id}`}
                >
                    Show Details
                </Link>

                {!isMy && (
                    <Button
                        className="btn btn--sm btn--primary"
                        onClick={handleApprove}
                    >
                        Start Discussion
                    </Button>
                )}

                <Button
                    className="btn btn--sm btn--default"
                    onClick={handleCancelRequest}
                >
                    {isMy ? "Cancel" : "Decline"}
                </Button>
            </div>
        </div>
    );
};
function LessonsRequestItem({ data, update }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
    const [isApproving, setIsApproving] = useState(false);
    const [isRejecting, setIsRejecting] = useState(false);

    const handleApprove = async (id) => {
        setIsApproving(true);
        const res = await dispatch(acceptRequestsPrivateLessonsFetch(data.id)).unwrap();

        if (res?.success) {
            navigate(`/chat/${res.data?.chat?.id}`);
            update && update();
        }

        setIsApproving(false);
    };

    const handleReject = async (id) => {
        setIsRejecting(true);
        const res = await dispatch(rejectRequestsPrivateLessonFetch(data.id)).unwrap();

        if (res?.success) {
            update && update();
        }

        setIsRejecting(false);
    };

    const studentProfile = data.student?.studentProfile;

    const totalHours = useMemo(() => calcTotalHours(data.dates), [data.dates])
    const totalPrice = data?.priceInHour * totalHours

    return (
        <>
            <div className="request-item">
                <div className="request-item__user">
                    <div className="ico ico--md img-wrapper rounded-full">
                        <img
                            alt="student"
                            src={getImageSrc(studentProfile?.photo)}
                        />
                    </div>

                    <div
                        className="col-group gap-4"
                        style={{ width: 224 }}

                    >
                        <h6 className="heading">
                            {studentProfile?.fullName}
                        </h6>

                        <p className="color-text">
                            {getDateRange(data?.dates)}
                        </p>
                    </div>
                    {!data?.isReaded && <span className="badge badge--primary">New</span>}
                </div>

                <div className="request-item__details">
                    <div className="col-group gap--xs">
                        <span className="label">
                            Total Price
                        </span>

                        <p className="fw-500 text--sm">
                            ${totalPrice}
                        </p>
                    </div>

                    <div className="col-group gap--xs">
                        <span className="label">
                            People
                        </span>

                        <p className="fw-500 text--sm">
                            {data?.peopleAttending}
                        </p>
                    </div>

                    <div className="col-group gap--xs">
                        <span className="label">
                            teaching
                        </span>

                        <p className="fw-500 text--sm">
                            {getTrainingStyle(data?.style)?.[0]?.title}
                        </p>
                    </div>

                    <div className="col-group gap--xs">
                        <span className="label">
                            Location
                        </span>

                        <p className="fw-500 text--sm">
                            {data?.address}
                        </p>
                    </div>
                </div>

                <div className="request-item__action">
                    {/* <button
                        className="btn btn--sm"
                        onClick={() => setOpenRescheduleModal(true)}
                        type="button"
                    >
                        <span className="ico">
                            <CalendarIcon />
                        </span>

                        RESCHEDULE
                    </button> */}

                    {/* <a href="#" className="btn btn--sm">Show Details</a>*/}

                    <button
                        className="btn btn--sm "
                        isLoading={isRejecting}
                        onClick={handleReject}
                    >
                        Decline
                    </button>

                    <Button
                        className="btn btn--sm btn--primary"
                        isLoading={isApproving}
                        onClick={handleApprove}
                    >
                        Start Discussion
                    </Button>

                    {/* <Link
                        className="btn btn--sm btn--square notification"
                        count="1"
                        to={`/chat/${data?.chat?.id}`}
                    >
                        <span className="ico">
                            <ChatIcon />
                        </span>
                    </Link> */}
                </div>
            </div>

            <ReschedulePrivateLessonModal
                data={data.dates}
                handleClose={() => {
                    setOpenRescheduleModal(false);
                    update && update()
                }}
                id={data.id}
                open={openRescheduleModal}
            />
        </>
    );
}
function LessonRequestsList({ data, update }) {
    return (
        <div className="request-items mb-24">
            {data.map((item) => (
                <LessonsRequestItem
                    key={item.id}
                    data={item}
                    update={update}
                />
            ))}
        </div>
    )
}
function RequestsList({ data, update }) {
    return (
        <div className="request-items mb-24">
            {data.map((item) => (
                <RequestItem
                    key={item.id}
                    data={item}
                    getRequests={update}
                    isHosting={item?.isHosting}
                />
            ))}
        </div>
    )
}