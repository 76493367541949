import React, {
    useEffect, useState
} from "react";

const AccordeonTypeArrow = ({
    children, title, isActive = false, className = "", objData = null, onClick
}) => {
    const [accordeonActive, setAccordeonActive] = useState(isActive);

    const clickHandler = () => {
        onClick && onClick(objData, !accordeonActive);
        setAccordeonActive((prev) => !prev);
    };

    useEffect(() => {
        setAccordeonActive(isActive);
    }, [isActive]);

    return (
        <div
            className={`accordion ${accordeonActive
                ? "open"
                : ""} ${className}`}
        >
            <div
                className="btn btn--lg accordion__button justify-start"
                onClick={clickHandler}
                style={{ textAlign: 'start' }}
            >
                <span dangerouslySetInnerHTML={{ __html: title }} />

                <span className="ico accordion__arrow">
                    <svg
                        fill="none"
                        height="14"
                        viewBox="0 0 14 14"
                        width="14"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_278_6526)">
                            <path
                                d="M5.25 2.625L9.625 7L5.25 11.375"
                                stroke="#02020A"
                                strokeLinecap="square"
                                strokeWidth="1.6"
                            />
                        </g>

                        <defs>
                            <clipPath id="clip0_278_6526">
                                <rect
                                    fill="white"
                                    height="14"
                                    width="14"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </span>
            </div>

            {accordeonActive && (
                <div className="accordion__body" >
                    {children}
                </div>
            )}

        </div>
    );
};

export default AccordeonTypeArrow;
