import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PageLoader from '../../../components/ui/PageLoader'
import { getSeminarsById } from '../../../redux/thunks/seminarsThunks'
import PlanSeminarPage from './PlanSeminarPage'

const EditSeminarPage = () => {
    const dispatch = useDispatch()
    const { seminarDetails, isLoading: isSeminarLoading } = useSelector((state) => state.seminars)
    const { seminarId } = useParams()
    
    useEffect(() => {
        dispatch(getSeminarsById(seminarId))
    }, [seminarId])

    if (isSeminarLoading) {
        return <PageLoader />
    }

    if (!seminarDetails) {
        return null
    }

    const prepareInitialData = (seminarDetails) => {
        return {
            seminarMinPrice: seminarDetails?.seminarMinPrice || null,
            seminarMaxPrice: seminarDetails?.seminarMaxPrice || null,
            sharedProfit: seminarDetails?.sharedProfit,
            address: seminarDetails?.address,
            coutry: seminarDetails?.coutry,
            continent: seminarDetails?.continent,
            specificBelt: seminarDetails?.specificBelt,
            isAvailablePrivateLesson: seminarDetails?.isAvailablePrivateLesson,
            traningStyle: seminarDetails?.traningStyle,
            targetAudience: seminarDetails?.targetAudience,
            allowUnder18: seminarDetails?.allowUnder18,
            availableDates: seminarDetails.seminarDates,
            seminarName: seminarDetails?.seminarName,
            teachingStyles: seminarDetails?.teachingStyles,
            maxGymCapacity: seminarDetails?.maxGymCapacity,
            summary: seminarDetails?.summary,
            instructors: seminarDetails?.instructors,
            languages: seminarDetails?.languages,
            isPrivate: seminarDetails?.isPrivate,
            minAttendance: seminarDetails?.minAttendance,
            minAttendanceRequirement: seminarDetails?.minAttendanceRequirement,
            minAttendanceDeadline: seminarDetails?.minAttendanceDeadline,
            minimalPriceTicket: seminarDetails.minimalPriceTicket,
            instructorPrice: seminarDetails.instructorPrice || null,
            coverFilename: seminarDetails?.photos?.find((photo) => photo?.id === seminarDetails?.coverId)?.originalname,
            photos: seminarDetails?.photos,
            faq: seminarDetails?.faq,
            startAt: seminarDetails?.startAt,
            endAt: seminarDetails?.endAt,
        };
    }
    return (
        <PlanSeminarPage seminarDetails={seminarDetails} defaultValues={prepareInitialData(seminarDetails)} />
    )
}

export default EditSeminarPage