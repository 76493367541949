import { Country } from "country-state-city";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Outlet, useLocation, useNavigate, useParams
} from "react-router-dom";
import PageLoader from "../../../components/ui/PageLoader";
import { EVENT_STATUS, USER_ROLE } from "../../../constants/enums";
import { EventHelper } from "../../../helpers/EventHelper";
import { checkErrors, getFullLocation } from "../../../helpers/utils";
import { resetSeminarFormData, setSeminarFormData } from "../../../redux/slices/planningSlice";
import { getSeminarsById } from "../../../redux/thunks/seminarsThunks";
import { calculateMinAttendanceDeadline, getStartDate } from "../helpers";
import PlanningSteps from "../parts/PlanningSteps";
import SeminarCardPreview from "../parts/SeminarCardPreview";
import withCommonData from "../parts/withCommonData";

const academyFields = {
    maxGymCapacity: "",
    address: '',
    specificBelt: "",


}
const instructorFields = {
    city: '',
    continent: "all",

}

const getInitialData = (role) => {
    const commonFields = {
        seminarMinPrice: null,
        seminarMaxPrice: null,
        sharedProfit: null,
        targetAudience: "any",
        languages: [],
        minAttendance: null,
        country: "",
        traningStyle: "",
        specificBelt: "",
        // minimumAttendance: "",
        // minAttendanceDeadline: "",
        allowUnder18: false,
        teachingStyles: [],
        photos: [],
        seminarName: "",
        summary: "",
        isPrivate: false,
        isAvailablePrivateLesson: false,
    };
    return {
        ...commonFields,
        ...(role === USER_ROLE.instructor ? instructorFields : {}),
        ...(role === USER_ROLE.academy ? academyFields : {}),
    };
};

const prepareInitialData = (seminarDetails) => {
    return {
        seminarMinPrice: seminarDetails?.seminarMinPrice ?? "",
        seminarMaxPrice: seminarDetails?.seminarMaxPrice ?? "",
        sharedProfit: seminarDetails?.sharedProfit,
        address: seminarDetails?.address,
        coutry: seminarDetails?.coutry,
        continent: seminarDetails?.continent,
        specificBelt: seminarDetails?.specificBelt,
        isAvailablePrivateLesson: seminarDetails?.isAvailablePrivateLesson,
        traningStyle: seminarDetails?.traningStyle,
        targetAudience: seminarDetails?.targetAudience,
        allowUnder18: seminarDetails?.allowUnder18,
        availableDates: seminarDetails.seminarDates,
        seminarName: seminarDetails?.seminarName,
        teachingStyles: seminarDetails?.teachingStyles,
        maxGymCapacity: seminarDetails?.maxGymCapacity,
        summary: seminarDetails?.summary,
        instructors: seminarDetails?.instructors,
        languages: seminarDetails?.languages,
        isPrivate: seminarDetails?.isPrivate,
        minAttendance: seminarDetails?.minAttendance,
        minAttendanceRequirement: seminarDetails?.minAttendanceRequirement,
        minAttendanceDeadline: seminarDetails?.minAttendanceDeadline,
        minimalPriceTicket: seminarDetails.minimalPriceTicket,
        instructorPrice: "",
        coverFilename: seminarDetails?.photos?.find((photo) => photo?.id === seminarDetails?.coverId)?.originalname,
        photos: seminarDetails?.photos,
    };
}


const PlanSeminarWrapper = ({
    type,
    chatId,
    user,
    formData,
    generalInfo,
    isInCountry,
    currentChatData,
}) => {
    const { pathname } = useLocation();
    const { seminarId, tourId } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorConfig, setErrorConfig] = useState({});
    const { seminarDetails, isLoading } = useSelector((state) => state.seminars);
    const [errors, setErrors] = useState({});
    const [preview, setPreview] = useState({});

    const handlePreviewChange = (name, value) => {
        setPreview((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleChange = (name, value) => {
        dispatch(setSeminarFormData({ name, value }));
        if (preview.hasOwnProperty(name)) {
            handlePreviewChange(name, value);
        }
    };

    // preview 
    useEffect(() => {
        const location = seminarDetails
            ? getFullLocation([Country.getCountryByCode(seminarDetails.country)?.name, seminarDetails.city])
            : getFullLocation([Country.getCountryByCode(generalInfo?.country)?.name, generalInfo?.city, generalInfo?.addressLine]);

        const instructors = seminarDetails?.instructors?.length
            ? seminarDetails.instructors.map((item) => ({
                id: item.user?.id,
                fullName: item.user?.instructorProfile?.fullName,
            }))
            : [];

        setPreview((prev) => ({
            ...prev,
            fullName: user?.profile?.name || user?.profile?.fullName || '',
            photo: user?.profile?.photo || '',
            location,
            availableDates: seminarDetails?.seminarDates,
            instructors,
            cover: seminarDetails?.cover || null,
        }));
    }, [generalInfo, seminarDetails, user]);

    useEffect(() => {
        setErrors(checkErrors(formData, errorConfig));
    }, [formData, errorConfig]);

    useEffect(() => {
        if (!seminarId) return;
        dispatch(getSeminarsById(seminarId));
    }, [seminarId]);

    useEffect(() => {
        if (seminarId) return;

        if (seminarId && seminarDetails && !isLoading) {
            if (location.state?.previousLocation !== "/template-editor") {
                const minAttendanceDeadline = seminarDetails?.minAttendanceDeadline || calculateMinAttendanceDeadline(getStartDate(seminarDetails?.seminarDates), isInCountry);
                dispatch(resetSeminarFormData(prepareInitialData({ ...seminarDetails, minAttendanceDeadline })));
            }
        }

        if (!seminarId && user) {
            if (location.state?.previousLocation !== "/template-editor") {
                dispatch(resetSeminarFormData(getInitialData(user.role)));
                setPreview({})
                navigate(`/plan-seminar/${tourId ? tourId : ""}`);
            }
        }
    }, [seminarDetails, user, type, tourId]);

    const step = EventHelper.getEventRequest(seminarDetails, chatId)?.step || currentChatData?.academyAccept?.step

    return (
        <>
            <PlanningSteps
                step={step}
                isCreated={seminarId}
                isActive={seminarId && seminarDetails?.status === EVENT_STATUS.active}
                chatId={chatId}
            />

            <section className="section-42">
                <div className="container">
                    {
                        formData && !isLoading
                            ? (
                                <div className="section-row-block plan-block">
                                    <div className="section-row-block__body">
                                        <Outlet
                                            context={[
                                                formData,
                                                handleChange,
                                                errors,
                                                handlePreviewChange,
                                                preview,
                                                setErrorConfig
                                            ]}
                                        />
                                    </div>
                                    {
                                        !pathname.includes("success") && <SeminarCardPreview {...preview} />
                                    }
                                </div>
                            ) : <PageLoader />
                    }
                </div>
            </section>
        </>
    );
};

export default withCommonData(PlanSeminarWrapper);

