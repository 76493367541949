import React, { useState } from "react";
import Input from "../../../components/ui/formElements/Input";
import Textarea from "../../../components/ui/formElements/Textarea";
import api from "../../../api";
import { toast } from "react-toastify";
import Button from "../../../components/ui/buttons/Button";
import TooltipInfo from "../../../components/parts/TooltipInfo";
import { explanations } from "../../../constants/explanations";
import { toBase64 } from "../../../helpers/utils";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from 'react-hook-form';

const MyAcademy = ({ userData }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'profile' });

    const [isLoading, setIsLoading] = useState(false);
    
    let defaultData = {
        email: userData?.email || '',
        phone: userData?.phone || '',
    }

    const profile = userData?.profile;

    if (profile && typeof profile === 'object') {
        defaultData = {
            ...defaultData,
            ...profile
        }
    }

    const [images64, setImages64] = useState({
        photo: null,
        cover: null
    });
        
    const [isPhotoError, setIsPhotoError] = useState(false);

    const {
        control,
        handleSubmit,
        formState: { dirtyFields, errors },
    } = useForm({ defaultValues: defaultData });

    async function submitHandler(data) {
        if (!images64.photo) {
            setIsPhotoError(true);

            return;
        }

        setIsLoading(true);

        if (!images64.photo) {
            setIsPhotoError(true);

            return;
        }

        const editedFieldsNames = Object.keys(dirtyFields);

        const changedFieldNames = editedFieldsNames.filter(
            (fieldName) => dirtyFields[fieldName]
        );

        const updateData = {};

        changedFieldNames.forEach((fieldName) => {
            updateData[fieldName] = data[fieldName];
        });

        if (updateData?.email || updateData?.phone) {
            const updatePayload = {};

            ["email", "phone"].forEach((item) => {
                if (updateData[item]) {
                    updatePayload[item] = updateData[item];
                }
            });
            const updateUserRes = await api.users.updateUser(updatePayload);

            if (updateUserRes.success) {
                toast.success(updateUserRes.message || "Updated");
            } else {
                toast.error(updateUserRes?.message || "Error");
            }
        }

        if (updateData?.cover || updateData?.photo || updateData?.name || updateData?.personInCharge || updateData?.bio) {
            const formData = new FormData();

            [
                "cover",
                "photo",
                "name",
                "personInCharge",
                "bio"
            ].forEach((item) => {
                if (updateData[item]) {
                    formData.append(item, updateData[item]);
                }
            });
            const updateInstructorRes = await api.profiles.updateAcademy(formData);

            if (updateInstructorRes.success) {
                toast.success(updateInstructorRes.message || "Updated");
            } else {
                toast.error(updateInstructorRes?.message || "Error");
            }
        }

        setIsLoading(false);
    }

    const changePhoto = async (file, field) => {
        const res = await toBase64(file);

        if (field === 'photo') {
            setIsPhotoError(false);
        }


        setImages64((prev) => {
            return {
                ...prev,
                [field]: res
            };
        });
    };

    return (
        <div className="settings-body settings-gap">
            <div className="sidebar-header heading-row">
                <h3 className="heading">
                    {t('title')}
                </h3>
            </div>

            <form
                className="form form-settings"
                onSubmit={handleSubmit(submitHandler)}
            >
                <div className="form-header">
                    <div className="row gutters-form">
                        <div className="col-12">
                            <div className="cover-block">
                                <div className="cover-load">
                                    {defaultData?.cover || images64?.cover
                                        ? (
                                            <img
                                                src={images64?.cover
                                                    ? images64?.cover
                                                    : process.env.REACT_APP_API_URL + defaultData?.cover}
                                                alt=""
                                            />
                                        )
                                        : null}

                                    <label className="photo-load-control">
                                        <Controller
                                            name={`cover`}
                                            control={control}
                                            render={({ field: { onChange } }) => (
                                                <>
                                                    <input
                                                        hidden={true}
                                                        onChange={(e) => {
                                                            changePhoto(e.target.files[0], "cover");
                                                            onChange(e.target.files[0]);
                                                        }}
                                                        type="file"
                                                    />

                                                    <div className="photo-load-btn btn">
                                                        <span className="ico">
                                                            <svg
                                                                fill="none"
                                                                height="22"
                                                                viewBox="0 0 22 22"
                                                                width="22"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M13.75 13.75L11 11"
                                                                    stroke="currentColor"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="1.2"
                                                                />

                                                                <path
                                                                    d="M11 11L8.25 13.75"
                                                                    stroke="currentColor"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="1.2"
                                                                />

                                                                <path
                                                                    d="M10.9993 18.3333V11"
                                                                    stroke="currentColor"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="1.2"
                                                                />

                                                                <path
                                                                    d="M7.33398 17.4166H5.50065C3.47561 17.4166 1.83398 15.775 1.83398 13.75C1.83398 11.7249 3.47561 10.0833 5.50065 10.0833L5.49148 10.0188C5.26333 8.42173 5.73945 6.80425 6.79647 5.58549C7.85349 4.36673 9.38737 3.66664 11.0007 3.66663C12.6139 3.66664 14.1478 4.36673 15.2048 5.58549C16.2619 6.80425 16.738 8.42173 16.5098 10.0188L16.5007 10.0833C18.5257 10.0833 20.1673 11.7249 20.1673 13.75C20.1673 15.775 18.5257 17.4166 16.5007 17.4166H14.6673"
                                                                    stroke="currentColor"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="1.2"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </>
                                            )}
                                        />
                                    </label>
                                </div>

                                <div className="photo-load">
                                    {
                                        defaultData?.photo || images64?.photo
                                            ? (
                                                <img
                                                    src={images64?.photo
                                                        ? images64?.photo
                                                        : process.env.REACT_APP_API_URL + defaultData?.photo}
                                                    alt=""
                                                />
                                            )
                                            : null
                                    }

                                    <label className="photo-load-control">
                                        <Controller
                                            name={`photo`}
                                            control={control}
                                            rules={{
                                                required: 'photoRequiredErrorText'
                                            }}
                                            render={({ field: { onChange } }) => (
                                                <>
                                                    
                                                    <input
                                                        hidden={true}
                                                        onChange={(e) => {
                                                            changePhoto(e.target.files[0], "photo");
                                                            onChange(e.target.files[0]);
                                                        }}
                                                        type="file"
                                                    />

                                                    <div className="photo-load-btn btn">
                                                        <span className="ico">
                                                            <svg
                                                                fill="none"
                                                                height="22"
                                                                viewBox="0 0 22 22"
                                                                width="22"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M13.75 13.75L11 11"
                                                                    stroke="currentColor"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="1.2"
                                                                />

                                                                <path
                                                                    d="M11 11L8.25 13.75"
                                                                    stroke="currentColor"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="1.2"
                                                                />

                                                                <path
                                                                    d="M10.9993 18.3333V11"
                                                                    stroke="currentColor"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="1.2"
                                                                />

                                                                <path
                                                                    d="M7.33398 17.4166H5.50065C3.47561 17.4166 1.83398 15.775 1.83398 13.75C1.83398 11.7249 3.47561 10.0833 5.50065 10.0833L5.49148 10.0188C5.26333 8.42173 5.73945 6.80425 6.79647 5.58549C7.85349 4.36673 9.38737 3.66664 11.0007 3.66663C12.6139 3.66664 14.1478 4.36673 15.2048 5.58549C16.2619 6.80425 16.738 8.42173 16.5098 10.0188L16.5007 10.0833C18.5257 10.0833 20.1673 11.7249 20.1673 13.75C20.1673 15.775 18.5257 17.4166 16.5007 17.4166H14.6673"
                                                                    stroke="currentColor"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="1.2"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </>
                                            )}
                                        />
                                    </label>
                                </div>
                            </div>

                            {isPhotoError || !!errors.photo?.message &&  <span className="error-input">{t('photoRequiredErrorText')}</span>}
                        </div>
                    </div>
                </div>

                <div className="form-body" >
                    <div className="form-items-list">

                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Controller
                                        name={`name`}
                                        control={control}
                                        rules={{
                                            required: 'academyNameRequiredErrorText'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                inputClasses="input--solid"
                                                label={t('academyName')}
                                                onChange={onChange}
                                                value={value}
                                                wrapperClasses="input--lg"
                                                error={errors.name?.message && t(errors.name?.message)}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Controller
                                        name={`personInCharge`}
                                        control={control}
                                        rules={{
                                            required: 'personInChargeRequiredErrorText'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                inputClasses="input--solid"
                                                label={t('personInCharge')}
                                                onChange={onChange}
                                                value={value}
                                                wrapperClasses="input--lg"
                                                error={errors.personInCharge?.message && 
                                                        t(errors.personInCharge?.message)}
                                            />
                                        )}
                                    />
                                    
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Controller
                                        name={`email`}
                                        control={control}
                                        rules={{
                                            required: 'emailRequiredErrorText'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                inputClasses="input--solid"
                                                label={t('email')}
                                                onChange={onChange}
                                                value={value}
                                                wrapperClasses="input--lg"
                                                error={errors.email?.message && t(errors.email?.message)}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Controller
                                        name={`phone`}
                                        control={control}
                                        rules={{
                                            required: 'phoneRequiredErrorText'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                inputClasses="input--solid"
                                                label={t('phone')}
                                                onChange={onChange}
                                                value={value}
                                                wrapperClasses="input--lg"
                                                error={errors.phone?.message && t(errors.phone?.message)}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <Controller
                                        name={`bio`}
                                        control={control}
                                        rules={{
                                            required: 'bioRequiredErrorText'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Textarea
                                                label={t('bio')}
                                                onChange={onChange}
                                                textareaClasses={"input--solid"}
                                                wrapperClasses={"input--lg"}
                                                rows={"6"}
                                                value={value}
                                                error={errors.bio?.message && t(errors.bio?.message)}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <div className="heading-row">
                                        <div className="flex-ico-ss-text">
                                            <span className="ico ico--sm rounded-full border-ico">
                                                <svg
                                                    fill="none"
                                                    height="18"
                                                    viewBox="0 0 18 18"
                                                    width="18"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        clipRule="evenodd"
                                                        d="M7.5 6.91594V6.06595C7.49995 5.85512 7.63216 5.66692 7.8305 5.59545L8.593 5.32095C8.85574 5.22635 9.14325 5.22635 9.406 5.32095L10.1695 5.59594C10.3677 5.66735 10.4998 5.8553 10.5 6.06595V6.91594C10.5 7.55244 9.6095 8.02344 9.2 8.20694C9.07285 8.26436 8.92715 8.26436 8.8 8.20694C8.39051 8.02344 7.5 7.55144 7.5 6.91594Z"
                                                        fillRule="evenodd"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />

                                                    <rect
                                                        height="13.5"
                                                        rx="2"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        width="12"
                                                        x="3"
                                                        y="2.25"
                                                    />

                                                    <path
                                                        d="M6.75 10.5H11.25"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />

                                                    <path
                                                        d="M7.875 12.75H10.125"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </span>

                                            <div className="row-group">
                                                <h4 className="heading">
                                                    {t('verifyAccountText')}
                                                </h4>
                                                <TooltipInfo content={explanations.verifyFighterID} />
                                            </div>
                                        </div>

                                        <div className="flex-group">
                                            <Button className="btn btn--primary btn--sm">
                                                <span className="info">
                                                    {t('verifyIdButtonText')}
                                                </span>

                                                <span className="ico ico-14">
                                                    <svg
                                                        fill="none"
                                                        height="18"
                                                        viewBox="0 0 19 18"
                                                        width="19"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <g clipPath="url(#clip0_146_4014)">
                                                            <path
                                                                d="M7.25 3.375L12.875 9L7.25 14.625"
                                                                stroke="currentColor"
                                                                strokeLinecap="square"
                                                                strokeWidth="2"
                                                            />
                                                        </g>

                                                        <defs>
                                                            <clipPath id="clip0_146_4014">
                                                                <rect
                                                                    fill="white"
                                                                    height="18"
                                                                    transform="translate(0.5)"
                                                                    width="18"
                                                                />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-footer --bordered">
                    <div className="flex-group justify-end">
                        <Button
                            className={`btn btn--primary btn--sm ${!Object.values(dirtyFields).length
                                ? "disabled"
                                : ""}`}
                            isLoading={isLoading}
                            type="submit"
                        >
                            <span>
                                {t('saveButtonText')}
                            </span>
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default MyAcademy;
