import classNames from "classnames";
import { useState } from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useParams, useSearchParams
} from "react-router-dom";
import api from "../../../api";
import { ReactComponent as AjustIcon } from "../../../assets/img/icon-ajust.svg";
import { ReactComponent as DotsIcon } from "../../../assets/img/icon-dots-horisontal.svg";
import Button from "../../../components/ui/buttons/Button";
import DropDown from "../../../components/ui/DropDown";
import { getImageSrc } from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import { acceptSeminarRequestFetch } from "../../../redux/thunks/seminarsThunks";
import SemianrActionsDropdown from "../../seminarsCamps/parts/SemianrActionsDropdown";
import StepBooking from "./StepBooking";
import { StepDesign } from "./StepDesign";
import { StepDetails } from "./StepDetails";
// import { StepLocationDate } from "./StepLocationDate";
import { StepLocationDate } from "./StepLocationDateNew";

const SidebarHeader = ({
    status, seminarDetails, statusMap, users = [], accepts = [], step
}) => {
    return (
        <>
            <div className="row-group gap--xxl pb-24 border-bottom mb-24">
                <div className="image-wrapper --small-thumb">
                    <img
                        alt={seminarDetails?.seminarName}
                        src={getImageSrc(seminarDetails?.photos?.find((photo) => photo.id === seminarDetails.coverId)?.url, null, 256)}
                    />
                </div>

                <div className="col-group gap--xxl justify-between">
                    <div className="seminar-label-info">
                        <h6 className="heading">
                            {seminarDetails?.seminarName}
                        </h6>
                    </div>

                    <Link
                        className="color-secondary ttu italic fw-600 text--xs underline"
                        to={
                            status === "done"
                                ? `/seminars/${seminarDetails?.id}`
                                : `/planed-by-academy/${seminarDetails?.id}`
                        }
                    >
                        {status === "done"
                            ? "Show Seminar"
                            : "Preview"}
                    </Link>
                </div>
            </div>

            <div className="row-group gap--sm pb-24 border-bottom mb-24">
                <span className="seminar__status">
                    Status:
                </span>

                <span
                    className={classNames("seminar__dot", {
                        "--done": status === "done",
                        "--rejected": status === "rejected"
                    })}
                />

                {statusMap[status] && (
                    <span className="chat-item__name">
                        {statusMap[status].label}
                    </span>
                )}
            </div>

            <div className="row-group gap--sm pb-24">
                <span className="seminar__status">
                    Participants:
                </span>

                {
                    !!users?.length && (
                        <div className="row-group gap--xs">
                            {users?.map((user) => (
                                <div
                                    key={user.id}
                                    className={"participant"}
                                >
                                    {accepts.find(item => item.userId === user.id)?.steps?.includes(step) && <span className={"sign"}></span>}
                                    <img
                                        src={getImageSrc(user.photo, null, 64)}
                                        alt={"user"}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
            </div>
        </>
    );
};

const ChatSidebarSeminar = ({ chatData, academyAccept }) => {
    const dispatch = useDispatch();
    const { chatId } = useParams();
    const user = useSelector(getUser);
    const seminarDetails = chatData.seminar;
    const ownerId = seminarDetails?.userId;
    const [searchParams] = useSearchParams()
    const [isTermsAccepted, setIsTermsAccepted] = useState(searchParams.get("terms") || false);
    const [isReadyForAccept, setIsReadyForAccept] = useState(false)
    const isActiveSeminar = academyAccept?.status === "active";
    const status = academyAccept
        ? isActiveSeminar
            ? "done"
            : chatData?.academyAccept?.step || "done"
        : seminarDetails.status === "active"
            ? "done"
            : [seminarDetails?.instructors?.[0]?.invite_status_instructor, seminarDetails?.instructors?.[0]?.invite_status_academy].includes("rejected")
                ? "rejected"
                : "pending";

    const isAccepted = academyAccept?.accepts?.length && academyAccept?.accepts.find(({ userId }) => userId === user?.id)?.steps?.includes(chatData?.academyAccept?.step);
    const someoneAccepted = !!academyAccept?.accepts?.length
        && academyAccept?.accepts.find(({ userId }) => userId !== user?.id)?.steps?.includes(chatData?.academyAccept?.step);
    const handleAccept = async () => {
        await dispatch(acceptSeminarRequestFetch({
            seminarId: seminarDetails.id,
            requestId: academyAccept?.id,
            body: {
                step: academyAccept.step
            }
        }))
    };

    const handleUnaccept = async (force = false) => {
        await api.events.unacceptStep({
            eventId: chatData.seminar.id,
            eventType: "seminar",
            body: {
                step: chatData?.academyAccept?.step,
                requestId: chatData?.academyAccept.id
            }, //location_and_date || details || design || terms
            query: force ? "forceStep=1" : ""
            // query: "forceStep=1"
        })
    };

    if (seminarDetails.status === "inactive") return null;

    const statusMap = {
        rejected: { label: "Request Rejected" },
        pending: { label: "Pending Request" },
        location_and_date: {
            label: "Discussing Location & Date",
            stepName: "Location & Date",
            adjustLink: `/edit-seminar/${chatId}/${chatData?.seminar?.id}/${academyAccept?.id}`,
        },
        details: {
            label: "Discussing Details",
            stepName: "Seminar Details",
            adjustLink: `/edit-seminar/${chatId}/${chatData?.seminar?.id}/${academyAccept?.id}/seminar-info`,
        },
        design: {
            label: "Discussing Design",
            stepName: "Design & Text",
            adjustLink: `/edit-seminar/${chatId}/${chatData?.seminar?.id}/${academyAccept?.id}/design-text`,
        },
        terms: {
            label: "Booking Specific Terms",
        },
        done: {
            label: "Active Seminar",
            button: null
        },
        in_progress: {
            label: "In progress",
        },
        archived: {
            label: "Ended",
        },
    };
    return (
        <aside className="chat-sidebar">
            <div className="chat-sidebar__header">
                <h6 className="heading">
                    Seminar Information
                </h6>
                <SemianrActionsDropdown event={seminarDetails} showCompletionConfirmationAction />
            </div>

            {
                <div className="chat-sidebar__body" >
                    <SidebarHeader
                        accepts={academyAccept?.accepts}
                        seminarDetails={seminarDetails}
                        status={status}
                        statusMap={statusMap}
                        users={chatData?.users}
                        step={chatData?.academyAccept?.step}
                        

                    />

                    <StepLocationDate
                        canEdit={!isActiveSeminar}
                        currentStatus={status}
                        seminarDetails={seminarDetails}
                        step={"location_and_date"}
                        requestId={academyAccept?.id}
                        status={["location", "pending", "rejected"]}
                        isReady={(value) => {setIsReadyForAccept(!value)}}
                    />

                    <StepDetails
                        currentStatus={status}
                        seminarDetails={seminarDetails}
                        step={"details"}
                        requestId={academyAccept?.id}
                        status={["details", "pending", "rejected"]}

                    />

                    <StepDesign
                        currentStatus={status}
                        seminarDetails={seminarDetails}
                        step={"design"}
                        requestId={academyAccept?.id}
                        status={["design", "pending", "rejected"]}
                    />

                    <StepBooking
                        canEdit={!isActiveSeminar}
                        currentStatus={status}
                        data={academyAccept?.terms}
                        seminarDetails={seminarDetails}
                        link={`/edit-seminar/${chatId}/${chatData?.seminar?.id}/${academyAccept?.id}/booking`}
                        status="terms"
                        step={"terms"}
                        requestId={academyAccept?.id}
                        onUnaccept={() => handleUnaccept(true)}

                    />

                    {
                        !isAccepted && !isActiveSeminar ? (
                            <div className="chat-sidebar__footer gap--xs">
                                <div className="row-group gap--xs">
                                    {
                                        // user?.id === ownerId && 
                                        statusMap?.[status]?.adjustLink && (
                                            <Link
                                                className="btn btn--default btn--sm text-14 w-full"
                                                to={statusMap?.[status]?.adjustLink}
                                            >
                                                <span className="ico">
                                                    <AjustIcon />
                                                </span>

                                                <span className="info">
                                                    Adjust
                                                </span>
                                            </Link>
                                        )
                                    }
                                    {
                                        status !== "terms" && (
                                            <Button
                                                className="btn btn--primary btn--sm text-14 w-full"
                                                onClick={handleAccept}
                                                disabled = {isReadyForAccept}
                                            >
                                                <span className="info">
                                                    Accept
                                                </span>
                                            </Button>
                                        )}
                                </div>
                                {
                                    someoneAccepted && (
                                        <Button
                                            className="btn btn--danger btn--sm text-14 w-full"
                                            onClick={() => handleUnaccept(true)}
                                        >
                                            <span className="info">
                                                Decline
                                            </span>
                                        </Button>
                                    )
                                }


                                {
                                    status === "terms" && !isTermsAccepted && (
                                        <div className="row-group gap--xs">
                                            <Button
                                                className="btn btn--default btn--sm text-14 w-full"
                                                onClick={() => setIsTermsAccepted(true)}
                                            >
                                                <span className="info">
                                                    Skip
                                                </span>
                                            </Button>

                                            <Button
                                                className="btn btn--primary btn--sm text-14 w-full"
                                                onClick={() => setIsTermsAccepted(true)}
                                            >
                                                <span className="info">
                                                    Accept
                                                </span>
                                            </Button>
                                        </div>
                                    )
                                }

                                {
                                    status === "terms" && isTermsAccepted && (
                                        <Link
                                            className="btn btn--primary btn--sm text-14 w-full"
                                            to={`/edit-seminar/${chatId}/${chatData?.seminar?.id}/${academyAccept?.id}/summary`}
                                        >
                                            <span className="ico">
                                                <AjustIcon />
                                            </span>

                                            <span className="info">
                                                Proceed to Summary
                                            </span>
                                        </Link>
                                    )
                                }

                            </div>
                        ) : !isActiveSeminar ? (
                            <div className="chat-sidebar__footer gap--xs">

                                <Button
                                    className="btn btn--danger btn--sm text-14 w-full"
                                    onClick={handleUnaccept}
                                >
                                    <span className="info">
                                        {"Unaccept "}
                                        {statusMap?.[status]?.stepName || ""}
                                    </span>
                                </Button>

                            </div>
                        ) : null}
                </div >
            }
        </aside>
    );
};

export default ChatSidebarSeminar;
