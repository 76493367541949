import TooltipInfo from '../../../components/parts/TooltipInfo'
import Textarea from '../../../components/ui/formElements/Textarea'
import { explanations } from '../../../constants/explanations'

const TermsTab = ({
    useFormReturn,
}) => {
    const { register } = useFormReturn

    return (
        <>
            <div className="row-group mb-36">
                <h4 className="">
                    Setup Booking Specific Terms
                </h4>
                <TooltipInfo content={explanations.bookingTerms} />
            </div>
            <div className="row mb-36">
                <div className="col-12">
                    <Textarea
                        label="Booking Specific Terms"
                        maxChars={1000}
                        placeholder="Type or paste booking specific terms here..."
                        style={{
                            resize: "vertical",
                            minHeight: 250
                        }}
                        textareaClasses="input--solid"
                        wrapperClasses="input--lg"
                        {...register("terms")}
                    />
                </div>
            </div>
        </>
    )
}

export default TermsTab