import { yupResolver } from '@hookform/resolvers/yup'
import { Country } from 'country-state-city'
import { format, isValid } from 'date-fns'
import { pick } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import api from '../../../api'
import Congratulations from '../../../components/parts/Congratulations'
import PageLoader from '../../../components/ui/PageLoader'
import { EVENT_STATUS, EVENT_STEP } from '../../../constants/enums'
import { convertObjectToFormData, getFullLocation } from '../../../helpers/utils'
import { useTabs } from '../../../hooks/useTabs'
import { createSeminar, updateSeminarFetch } from '../../../redux/thunks/seminarsThunks'
import { createTourFetch, updateTourFetch } from '../../../redux/thunks/toursThunks'
import SeminarCardPreview from '../../planning/parts/SeminarCardPreview'
import FooterActions from '../components/FooterActions'
import TabsNavigation from '../components/TabsNavigation'
import withCommonData from '../components/withCommonData'
import { PLANNING_STATUS } from '../constants/emuns'
import { tourDatesSchema, tourInformationSchema, tourSchema } from '../validation/tourSchema'
import TourDateTab from './tabs/TourDateTab'
import TourDesignTab from './tabs/TourDesignTab'
import TourInformationTab from './tabs/TourInformationTab'


const PlanTourPage = ({
    chatId,
    user,
    generalInfo,
    isInCountry,
    currentChatData,
    tourDetails,
    defaultValues
}) => {
    const { tourId, requestId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isCreated, setIsCreated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const planningStatus = (() => {
        if (tourDetails?.status === EVENT_STATUS.active) {
            return PLANNING_STATUS.active;
        }
        return chatId ? PLANNING_STATUS.negotiation : PLANNING_STATUS.draft;
    })();
    const currentStep = tourDetails?.status === EVENT_STATUS.active ? EVENT_STEP.design : currentChatData?.academyAccept?.step

    const tabItems = [
        {
            label: "Time & Date",
            id: EVENT_STEP.date,
            editableStatus: [PLANNING_STATUS.draft, PLANNING_STATUS.negotiation],
            schema: tourDatesSchema
        },
        {
            label: `Seminar Information`,
            id: EVENT_STEP.details,
            editableStatus: [PLANNING_STATUS.draft, PLANNING_STATUS.negotiation],
            schema: tourInformationSchema
        },
        {
            label: "Design & Text",
            id: EVENT_STEP.design,
            editableStatus: [PLANNING_STATUS.draft, PLANNING_STATUS.negotiation, PLANNING_STATUS.active],
            schema: tourSchema
        },
    ];

    const filteredTabs = tabItems.filter(({ editableStatus }) => editableStatus.includes(planningStatus))
    const { activeTab, setActiveTab, activeTabIndex } = useTabs(filteredTabs, 't');
    const [previewData, setPreviewData] = useState({});

    // Set active tab based on chat data if it exists
    useEffect(() => {
        if (currentStep) {
            setActiveTab(currentStep)
        } else {
            setActiveTab(EVENT_STEP.date)
        }
    }, [currentChatData, setActiveTab])

    const currentSchema = planningStatus === PLANNING_STATUS.draft && activeTabIndex === filteredTabs.length - 1 ?
        tourSchema : filteredTabs[activeTabIndex]?.schema;

    const resolver = yupResolver(currentSchema);
    const useFormReturn = useForm({
        resolver,
        defaultValues: {
            isPrivate: false,
            ...defaultValues
        }
    })
    const { handleSubmit, watch, formState: { errors, touchedFields, dirtyFields } } = useFormReturn
    const editedFields = Object.keys(dirtyFields).filter(key => dirtyFields[key] && errors[key] === undefined)

    watch((values, options) => {
        if (['coverFilename', 'name', 'traningStyle', 'startAt', 'endAt'].includes(options.name)) {
            setPreviewData((prev) => ({
                ...prev,
                [options.name]: values[options.name],
                cover: options.name === 'coverFilename' ? values[options.name] : prev.cover
            }))
        }
    })

    useEffect(() => {
        const location = tourDetails
            ? getFullLocation([Country.getCountryByCode(tourDetails.country)?.name, tourDetails.city])
            : getFullLocation([Country.getCountryByCode(generalInfo?.country)?.name, generalInfo?.city, generalInfo?.addressLine]);

        const instructors = tourDetails?.instructors?.length
            ? tourDetails.instructors.map((item) => ({
                id: item.user?.id,
                fullName: item.user?.instructorProfile?.fullName,
            }))
            : [];

        setPreviewData((prev) => ({
            ...prev,
            fullName: user?.profile?.name || user?.profile?.fullName || '',
            seminarName: tourDetails?.seminarName || '',
            photo: user?.profile?.photo || '',
            location,
            instructors: instructors,
            availableDates: tourDetails?.seminarDates,
            cover: tourDetails?.cover || null,

        }));
    }, [generalInfo, tourDetails, user])

    useEffect(() => {
        console.log(errors, 'errors');
    }, [errors])

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const errorMessages = Object.keys(errors).map(key => errors[key].message)
            errorMessages.map(message => toast.warn(message))
        }
    }, [errors])



    const tabComponents = {
        [EVENT_STEP.date]: (
            <TourDateTab
                useFormReturn={useFormReturn}
                role={user?.role}
                generalInfo={generalInfo}
                planningStatus={planningStatus}
            />
        ),
        [EVENT_STEP.details]: (
            <TourInformationTab
                useFormReturn={useFormReturn}
                role={user?.role}
                planningStatus={planningStatus}
                preview={previewData}
                setPreview={setPreviewData}
            />
        ),
        [EVENT_STEP.design]: (
            <TourDesignTab
                planningStatus={planningStatus}
                useFormReturn={useFormReturn}
                role={user?.role}
                setPreview={setPreviewData}
                isInCountry={isInCountry}

            />
        ),
    }

    const renderTab = (activeTab) => {
        return tabComponents[activeTab]
    }

    const transformData = (data) => {
        const result = { ...data }

        const startAt = new Date(result.startAt)
        const endAt = new Date(result.endAt)
        if (isValid(startAt) && isValid(endAt)) {
            result.startAt = startAt.toISOString()
            result.endAt = endAt.toISOString()
        }
        return result
    }

    const update = async (data) => {
        const dataToUpdate = pick(data, editedFields);
        const body = convertObjectToFormData(dataToUpdate, new FormData(), false);
        if (!data?.countries?.length) {
            body.append('countries', null)
        }
        let res = await dispatch(updateTourFetch({
            id: tourId,
            body
        })).unwrap();

        if (res) {
            navigate(`/my-seminars/`);
        }
    }

    const create = async (data) => {
        const body = convertObjectToFormData(transformData(data));

        const res = await dispatch(createTourFetch(body)).unwrap();
        if (res?.success) {
            setIsCreated(true);
        }
    }


    const onSubmit = async (data) => {
        try {
            console.log("Form data:", data);
            setIsLoading(true);

            if (activeTabIndex < filteredTabs.length - 1) {
                if (planningStatus === PLANNING_STATUS.negotiation) {
                    await update(data);
                } else {
                    setActiveTab(filteredTabs[activeTabIndex + 1].id);
                }
            } else {
                console.log("Final Submit:", data);
                if (tourId) {
                    await update(data);
                } else {
                    await create(data);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        if (chatId) {
            navigate(`/chat/${chatId}`);
            return;
        }
        if (activeTab === EVENT_STEP.date) {
            navigate("/");
        } else {
            const currentStepIndex = filteredTabs.findIndex((tab) => tab.id === activeTab);
            if (currentStepIndex > 0) {
                const previousStep = filteredTabs[currentStepIndex - 1];
                setActiveTab(previousStep.id);
            }
        }
    };

    if (isLoading) {
        return <PageLoader />
    }
    return (
        <>
            <TabsNavigation
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabItems={filteredTabs}
                currentStep={currentStep}
                planningStatus={planningStatus}
            />
            <section className="section-42">
                <div className="container">
                    {!isCreated && (
                        <div className="section-row-block plan-block">
                            <form onSubmit={handleSubmit(onSubmit)} className="section-row-block__body">
                                {renderTab(activeTab)}
                                <FooterActions
                                    onCancel={handleCancel}
                                    submitButtonText={(activeTabIndex === filteredTabs.length - 1 && planningStatus === PLANNING_STATUS.draft) ? "Publish" : (planningStatus !== PLANNING_STATUS.draft) || (activeTabIndex === filteredTabs.length - 1) ? "Apply Changes" : "Continue"}
                                    showSubmitIcon={(activeTabIndex !== filteredTabs.length - 1) || (planningStatus !== PLANNING_STATUS.draft)}
                                    chatId={chatId}
                                    isTerms={activeTab === EVENT_STEP.terms}
                                />
                            </form>
                            <SeminarCardPreview {...previewData} />
                        </div>
                    )}
                    {isCreated && (
                        <Congratulations text="Your pending tour has been created."
                            additionalLink={{
                                to: "/planed-by-academy?sort_as=desc",
                                title: "Invite Academies"
                            }}
                        />
                    )}

                </div>
            </section>
        </>
    )
}

export default withCommonData(PlanTourPage)