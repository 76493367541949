import {Country} from "country-state-city";
import {capitalize} from "lodash";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {useSearchParams} from "react-router-dom";
import CardInstructor from "../../components/parts/cards/CardInstructor";
import {PaginationTemplate} from "../../components/parts/PaginationTemplate";
import SelectFilters from "../../components/parts/SelectFilters";
import CheckboxSwitch from "../../components/ui/formElements/CheckboxSwitch";
import SelectOne from "../../components/ui/formElements/SelectOne";
import Loader from "../../components/ui/Loader";
import {
    belts, languages, locations, trainingStyle
} from "../../constants";
import useDebounce from "../../hooks/useDebaunce";
import {getInstructorsAllFetch} from "../../redux/thunks/instructorsThunks";
import {ReactComponent as SearchIcon} from "../../assets/img/search-loupe.svg";
import {generateItems} from "../../helpers/utils";

export const Instructors = () => {
    const dispatch = useDispatch();
    const {instructors, pagination, isLoading} = useSelector((state) => state.instructors);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const [filters, setFilters] = useState({
        sort_by: "",
        sort_as: "asc",
        gender: "",
        location: "",
        price: "",
        trainingStyle: "",
        belt: "",
        language: "",
        search: "",
        page: "",
    });
    // const allCountries = Country.getAllCountries();

    // const locationOptions = generateItems(locations)?.map((item) => ({
    //     value: item.key,
    //     label: item.title
    // })) || [];
    const allCountries = Country.getAllCountries();
    const locationOptions = allCountries?.map((item) => ({
        value: item.isoCode,
        label: item.name
    })) || [];
    const priceOptions = [
        "$$$$$",
        "$$$$",
        "$$$",
        "$$",
        "$"
    ].map((item) => ({
        label: item,
        value: item
    }));
    const trainingStyleOptions = trainingStyle.map(({title, key}) => ({
        label: title,
        value: title
    }));
    const beltOptions = belts.map(({title, key}) => ({
        label: title,
        value: key
    }));
    const languageOptions = languages.map(({title, key}) => ({
        label: title,
        value: title
    }));
    const sortOptions = [
        {label: "Price High to Low", value: "privatePrice&desc"},
        {label: "Price Low to High", value: "privatePrice&asc"},
        {label: "Popularity", value: "privateViews&desc"},
        // {label: "Id", value: "id"},
        // {label: "Full Name", value: "fullName"},
        // {label: "Gender", value: "gender"},
        // {label: "Locations", value: "locations"},
    ];
    const filtersData = [
        {
            label: "Location",
            query: "country",
            options: locationOptions,
            all: true
        },
        {
            label: "Seminar Price",
            query: "price",
            options: priceOptions,
            all: true
        },
        {
            label: "Gi or No-Gi",
            query: "trainingStyle",
            options: trainingStyleOptions,
            all: true
        },
        {
            label: "Belt Rank",
            query: "belt",
            options: beltOptions,
            all: true
        },
        {
            label: "Language",
            query: "language",
            options: languageOptions,
            all: true
        }
    ];

    // const handleFiltersChange = (key, value) => {

    //     setFilters((prevFilters) => ({
    //         ...prevFilters,
    //         [key]: value
    //     }));
    // };


    const handleFiltersChange = (key, value) => {
        if (typeof value === "string" && value.includes("&")) {
            const [sortByValue, sortOrder] = value.split("&");
            setFilters((prevFilters) => ({
                ...prevFilters,
                [key]: sortByValue,
                sort_as: sortOrder,
            }));
        } else {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [key]: value,
            }));
        }
    };

    useEffect(() => {
        handleFiltersChange("search", debouncedSearchTerm);
    }, [debouncedSearchTerm]);
    useEffect(() => {
        const cleanedFilters = Object.fromEntries(Object.entries(filters).filter(([key, value]) => value !== "" && value !== false));

        setSearchParams(cleanedFilters);
    }, [filters, debouncedSearchTerm]);
    useEffect(() => {
        const queries = searchParams.toString().replaceAll("%24", "$");

        dispatch(getInstructorsAllFetch(queries));
    }, [searchParams]);

    return (
        <main className="content current-seminars about-us">
            <div>
                {/* <section className="first-screen first-screen-instructors">
                        <div className="firts-screen-bg">
                            <img src={require('../../assets/img/instructors-img-bg.jpg')} alt="" />
                            <div className="firts-screen-bg-effect"></div>
                        </div>
                        <div className="container">
                            <div className="first-screen-body first-screen-body--center">
                                <h2 className="heading mb-32">Instructors</h2>
                                <p className="mb-42">Meet the instructors currently teaching on our platform</p>
                                <div className='first-screen-search'>
                                    <div className="form-group input--lg input--icon-left">
                                        <div className="input-wrapper">
                                            <span className="ico">
                                                <SearchIcon />
                                            </span>
                                            <input
                                                className="input input--solid"
                                                type="text"
                                                placeholder="Browse instructors catalog..."
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                <section className="first-screen first-screen-instructors">
                    <div className="firts-screen-bg">
                        <img
                            alt=""
                            src={require("../../assets/img/instructors-img-bg.jpg")}
                        />

                        <div className="firts-screen-bg-effect"/>
                    </div>

                    <div className="container">
                        <div className="first-screen-body first-screen-body--center">
                            <div className="section-heading">
                                <h1 className="heading fs-48">
                                    Instructors
                                </h1>

                                <p className="small-paragraph">
                                    Meet the instructors currently teaching on our platform
                                </p>
                            </div>

                            <form className="first-screen-search">
                                <div className="form-group input--lg input--icon-left">
                                    <div className="input-wrapper">
                                        <span className="ico">
                                            <SearchIcon/>
                                        </span>

                                        <input
                                            className="input input--solid"
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            placeholder="Browse instructors catalog..."
                                            type="text"
                                            value={searchTerm}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>

                <section className="pt-42 pb-160">
                    <div className="container">
                        <div className="seminar-filters">
                            <div className="seminar-filters-grid lg-flex justify-lg-between">
                                <SelectFilters
                                    filters={filters}
                                    filtersData={filtersData}
                                    handleChange={handleFiltersChange}
                                />

                                <SelectOne
                                    onChange={(option) => onChange("sort_by", option.value)}
                                    options={sortOptions}
                                    value={filters.sort_by}
                                    wrapperClasses="lg-hidden form-group select--outline w-150 input--md select-border-option"
                                />

                                <div className="hidden lg-flex items-center gap-24 mb-24">
                                    <CheckboxSwitch
                                        checked={filters.gender === "female"}
                                        onChange={(e) => handleFiltersChange("gender", e.target.checked
                                            ? "female"
                                            : "")}
                                        text="Women only"
                                    />

                                    <CheckboxSwitch
                                        checked={filters.gender === "male"}
                                        onChange={(e) => handleFiltersChange("gender", e.target.checked
                                            ? "male"
                                            : "")}
                                        text="Men only"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center justify-between flex-wrap gap-24">
                            <span className="instructors__counter mb-12">
                                {pagination?.total}

                                {" "}

                                instructors are available
                            </span>

                            <div className="flex items-center mb-12">
                                <span className="label-sort">
                                    Sort by:
                                </span>

                                <div className="form-group select--sort input--md">
                                    <SelectOne
                                        isSearchable={false}
                                        onChange={(option) => handleFiltersChange("sort_by", option.value)}
                                        options={sortOptions}
                                        value={filters.sort_by}
                                        wrapperClasses="w-120"
                                    />

                                </div>
                            </div>
                        </div>

                        <div className="instructors-list row gx-24 gy-70 mb-70">
                            {
                                !isLoading && instructors?.length
                                    ? instructors.map((instructor) => (
                                        <div
                                            key={instructor.id}
                                            className="col-6 col-lg-3"
                                        >
                                            <CardInstructor data={instructor}/>
                                        </div>
                                    ))
                                    : isLoading
                                        ? <Loader/>
                                        : (
                                            <div className="flex items-center justify-center flex-auto">
                                                <h1>
                                                    Not found
                                                </h1>
                                            </div>
                                        )
                            }
                        </div>

                        {
                            !isLoading && (
                                <PaginationTemplate
                                    {...pagination}
                                    onPageChange={(page) => handleFiltersChange("page", page)}
                                />
                            )}
                    </div>
                </section>

            </div>

        </main>
    );
};
