import { useState } from "react";
import { SeminarTemplate } from "../parts/SeminarTemplate";
import { useSelector } from "react-redux";
import { Country } from "country-state-city";
import {
    belts, languages, trainingStyle
} from "../../../constants";
import { getAllSeminarsWithFiltersFetch } from "../../../redux/thunks/seminarsThunks";
import { useTranslation } from "react-i18next";

export const SeminarMain = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    const { seminars, pagination, isLoading } = useSelector((state) => state.seminars);
    const [filters] = useState({
        sort_by: "",
        sort_as: "desc",
        gender: "",
        location: "",
        // price: "",
        trainingStyle: "",
        belt: "",
        language: "",
        search: "",
        page: "",
        limit: ""
    });
    const allCountries = Country.getAllCountries();
    const locationOptions = allCountries?.map((item) => ({
        value: item.isoCode,
        label: item.name
    })) || [];
    const priceOptions = [
        "$$$$$",
        "$$$$",
        "$$$",
        "$$",
        "$"
    ].map((item) => ({
        label: item,
        value: item
    }));
    const trainingStyleOptions = trainingStyle.map(({ title, key }) => ({
        label: title,
        value: title
    }));
    const beltOptions = belts
    .filter(({ key }) => ["purple_belt", "brown_belt", "black_belt"].includes(key))
    .map(({ title, key }) => ({
        label: title,
        value: key
    }));

    const languageOptions = languages.map(({ title, key }) => ({
        label: title,
        value: title
    }));
    // const sortOptions = [
    //     {
    //         label: t('date'),
    //         value: "id"
    //     }, {
    //         label: t('price'),
    //         value: "seminarMinPrice"
    //     }
    // ];
    const sortOptions = [
        {label: "Price High to Low", value: "minimalPriceTicket&desc"},
        {label: "Price Low to High", value: "minimalPriceTicket&asc"},
        {label: "Popularity", value: "seminar_views&desc"},
        // {label: "Id", value: "id"},
        // {label: "Full Name", value: "fullName"},
        // {label: "Gender", value: "gender"},
        // {label: "Locations", value: "locations"},
    ];
    const filtersData = [
        {
            label: t('location'),
            query: "location",
            options: locationOptions,
            all: true
        },
        // {
        //     label: t('seminar_price'),
        //     query: "price",
        //     options: priceOptions,
        //     all: true
        // },
        {
            label: t('gi'),
            query: "trainingStyle",
            options: trainingStyleOptions,
            all: true
        },
        {
            label: t('length'),
            query: "lengthDays",
            options: Array.from({ length: 10 }, (_, i) => ({
                label: i + 1,
                value: i + 1
            })),
            all: true
        },
        {
            label: t('belt'),
            query: "belt",
            options: beltOptions,
            all: true
        },
        {
            label: t('lang'),
            query: "language",
            options: languageOptions,
            all: true
        }
    ];

    return (
        <SeminarTemplate
            baseQuery="status=active"
            fethFuntion={getAllSeminarsWithFiltersFetch}
            filterLinks={[
                {
                    label: t('seminars'),
                    link: "/seminars"
                }, {
                    label: t('camps'),
                    link: "/camps"
                }
            ]}
            filters={filters}
            filtersData={filtersData}

            firsScreenOptions={{
                title: t('title'),
                description: t('description'),
                placeholder: t('placeholder'),
                imageBg: require("../../../assets/img/current-seminars.png")
            }}
            isLoading={isLoading}

            pagination={pagination}
            seminars={seminars}
            sotrBy={sortOptions}
            name={t('seminars')}
        />
    );
};
