import { Country } from "country-state-city";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    Link
} from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../../assets/img/icon-decor-city.svg";
import { ReactComponent as DotsIcon } from "../../../assets/img/icon-dots.svg";
import { EVENT_STATUS, EVENT_TYPE, USER_ROLE } from "../../../constants/enums";
import { EventHelper } from "../../../helpers/EventHelper";
import {
    getDateRange, getImageSrc, getTrainingStyle
} from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import Button from "../../ui/buttons/Button";
import DropDown from "../../ui/DropDown";

const CardSeminar = ({ data = {}, actions, linkPrefix = "", isBooked }) => {
    if (!data) {
        return null
    }
    const getLink = () => {
        const isCamp = data?._type === EVENT_TYPE.camp
        const isTour = data?._type === EVENT_TYPE.tour

        if (linkPrefix) {
            return `${linkPrefix}/${data?.id}`
        }

        if (!data.status === EVENT_STATUS.pending) {
            if (isTour) {
                return `/tours/${data?.id}`
            }
            return `/${isCamp ? "camps" : "seminars"}/${data?.id}`
        }

        if (data?.user?.role === USER_ROLE.instructor) {
            if (isTour) {
                return `/planed-by-insctructor/tours/${data?.id}`
            }

            return `/planed-by-insctructor/${isCamp ? "camps/" : ""}${data?.id}`
        }

        if (data?.user?.role === USER_ROLE.academy) {
            return `/planed-by-academy/${isCamp ? "camps/" : ""}${data?.id}`
        }
    }

    return (
        <div className="card-seminar">
            <CardImageBlock
                data={data}
                link={getLink()}
            />

            <CardBody data={data} />

            <CardFooter
                actions={actions}
                data={data}
                linkPrefix={linkPrefix}
                isBooked={isBooked}
                link={getLink()}
                type={data._type}
            />
        </div>
    );
};

function DatesRange({ data }) {
    const dateArray = data?.seminarDates
        ? getDateRange(data?.seminarDates)?.split(",") || []
        : getDateRange([data.startAt, data.endAt], true)?.split(",") || [];

    if (!dateArray?.length) return;

    return (
        <span className="card-seminar__info-date">
            {dateArray[0]}

            {", "}
            &nbsp;
            <span className="color-primary">
                {dateArray[1]}
            </span>
        </span>
    );
}

function CardImageBlock({ data, link }) {
    const trainingStyle = getTrainingStyle(data?.traningStyle);

    function getEventCover(event) {
        // if (event?._type === EVENT_TYPE.seminar) return (event?.photos || []).find(ph => ph.id === event.coverId)?.url;

        // if (event?._type === EVENT_TYPE.camp) return (event?.photos || []).find(ph => ph.isCover)?.url;

        // if (event?._type === EVENT_TYPE.tour) return (event?.photos || []).find(ph => ph.isCover)?.file;

        return (event?.photos || []).find(ph => ph.id === event.coverId)?.url || (event?.photos || []).find(ph => ph.isCover)?.file
    }


    return (
        <div className="card-seminar__img-block">
            <div className="card-seminar__img-info">
                <DatesRange data={data} />

                <span className="card-seminar__gi ml-auto">
                    {trainingStyle[0]?.icon}

                    <span>
                        {trainingStyle[0]?.title}
                    </span>
                </span>
            </div>

            <Link to={link}>
                <div className="card-seminar__img">
                    <img
                        alt="cover"
                        src={getImageSrc(data?.cover || getEventCover(data), require("../../../assets/img/photo-placeholder.svg").default, 512)}
                    />
                </div>
            </Link>

            {
                data.status === "pending" && (
                    <div className="card-seminar__label">
                        {"Seeking "}

                        {data?.user?.role === USER_ROLE.academy
                            ? "Instructor"
                            : "Academy"}
                    </div>
                )}
        </div>
    );
}

function CardBody({ data }) {
    const user = useSelector(getUser);

    const getLocation = (data) => {
        return EventHelper.location(data);
    };

    const academyProfile = data?.userAcademy?.academyProfile;
    const isPending = data.status === EVENT_STATUS.pending;
    const isAcademyOwner = user?.role === USER_ROLE.academy

    return (
        <div className="card-seminar__body">
            <div className="card-seminar__group-city">
                <div className="ico">
                    <LocationIcon />
                </div>

                <span className="truncate capitalize">
                    {getLocation(data)}
                </span>
            </div>

            <h4 className="heading line-clamp-2">
                {data?.seminarName || data?.name}
            </h4>

            {academyProfile && (
                <Link to={`/academies/${data?.userAcademy?.id}`} className="card-user-block mb-18">
                    <div className="ico ico--xs">
                        <img
                            alt="avatar"
                            src={getImageSrc(academyProfile?.photo, null, 64)}
                        />
                    </div>

                    <div className="card-user-block__name">
                        {academyProfile?.name || "Name not specified"}
                    </div>
                </Link>
            )}

            {!(isAcademyOwner && isPending) && !!data?.instructors?.length && (
                <div className="card-seminar__group-tags">
                    {data?.instructors?.map((item) => (
                        <span key={item.id}>
                            <Link
                                to={`/instructors/${item.user?.id}`}
                                className={"underline"}
                            >
                                {item.user?.instructorProfile?.fullName}
                            </Link>
                        </span>
                    ))}
                </div>
            )}

        </div>
    );
}

function Actions({ actions, data }) {
    return (
        <DropDown
            dropDownClasses="ml-auto card-actions"
            dropDownListClasses="dropdown-menu"
            labelButton={(
                <Button
                    className="btn btn--square btn--md radius"
                >
                    <DotsIcon />
                </Button>
            )}
        >
            <div className="dropdown-menu__body--item">
                {
                    actions.map((item, index) => (
                        <Button
                            key={index}
                            className="btn btn--sm btn--thin active"
                            onClick={item.action}
                        >
                            {item.label}
                        </Button>
                    ))
                }
            </div>
        </DropDown>
    );
}

function CardFooter({ data, actions = [], link, isBooked, type }) {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    const user = useSelector(getUser);
    const isPending = data.status === EVENT_STATUS.pending;
    const isClient = user?.role !== USER_ROLE.academy && user?.role !== USER_ROLE.instructor;
    // const location = useLocation();
    // const currentPath = location.pathname;
    // const [basePath, id] = currentPath.split("/").filter(Boolean)
    //     .slice(-2);
    // const newUrl = id && id !== "camps"
    //     ? currentPath.replace(id, data?.id)
    //     : data?.id?.toString();


    const ticketsLeft = (data?.maxGymCapacity || 0) - (data?.ticketsPurchased || 0)
    return (
        <div className="card-seminar__footer">
            {
                !isPending ? (
                    data?.minimalPriceTicket && (
                        <div className="price-group">
                            <span className="price-group__price">
                                ${data?.minimalPriceTicket}
                            </span>
                            <span className="color-grey mr-8">
                                / {t('ticket')}
                            </span>

                            <span className="price-group__amount">
                                {ticketsLeft} {t('left')}
                            </span>
                        </div>
                    )
                ) : (
                    <div className="price-group">
                        {
                            data?.sharedProfit ? (
                                <span className="price-group__price">
                                    Shared Profit
                                </span>
                            ) : (
                                <>
                                    <span className="color-grey mr-8">
                                        {t('from')}
                                    </span>
                                    <span className="price-group__price">
                                        ${data?.seminarMinPrice || data?.priceFrom}
                                    </span>
                                </>
                            )
                        }
                    </div>
                )
            }

            <div className="row-group gap--xs">
                {/* {
                    (isClient && !isPending) && !isBooked
                        ? (
                            <Link
                                to={`/book/${data?._type === EVENT_TYPE.camp ? "camp/" : ""}${data.id}`}
                                className={classNames("btn btn--primary btn--md radius", {
                                    disabled: !ticketsLeft
                                })}
                                onClick={!ticketsLeft ? (e) => e.preventDefault() : null}
                            >
                                {t('book_now')}
                            </Link>
                        )


                        : (
                            <Link
                                to={link}
                                className="btn btn--primary btn--md radius"
                            >
                                {t('view_seminar')}
                            </Link>
                        )

                } */}
                <Link
                    to={link}
                    className="btn btn--primary btn--md radius"
                >

                    {(isClient && !isPending) && !isBooked ? t('book_now') : (type === EVENT_TYPE.tour ? t('view_tour') : t('view_seminar'))}
                </Link>

                {!!actions?.length && (
                    <Actions
                        data={data}
                        actions={actions}
                    />
                )}
            </div>
        </div >
    );
}

export default CardSeminar;
