import { ReactComponent as DotIcon } from "../../../assets/img/icon-dot.svg";

export const CardDetails = ({ isEdited, label, children }) => (
    <div className="form-group input--lg">
        <label className="label flex items-center gap--xs">
            {label}
            {isEdited && (
                <span className="flex">
                    <DotIcon />
                </span>
            )}
        </label>
        <div className="col-group gap--xs text--sm">
            {children && ((Array.isArray(children) && children.length) || !Array.isArray(children))
                ? children
                : "Not specified"}
        </div>
    </div>
);

