import {
    useCallback,
    useEffect, useRef, useState
} from "react";
import SelectOne from "../../components/ui/formElements/SelectOne";
import HeaderTemplateEditor from "./parts/HeaderTemplateEditor";
import { ReactComponent as CalcMinusIcon } from "../../assets/img/ico-calc-minus.svg";
import { ReactComponent as CalcPlusIcon } from "../../assets/img/ico-calc-plus.svg";
import { ReactComponent as CaretLeftIcon } from "../../assets/img/ico-carret-left.svg";
import { ReactComponent as CheckIcon } from "../../assets/img/ico-check-editor.svg";
import { ReactComponent as RedoIcon } from "../../assets/img/ico-editor-next.svg";
import { ReactComponent as UndoIcon } from "../../assets/img/ico-editor-prev.svg";
import { ReactComponent as QuestionIcon } from "../../assets/img/ico-question.svg";
import {
    getFontEmbedCSS, toPng
} from "html-to-image";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    useLocation, useNavigate, useSearchParams
} from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { ReactComponent as AlignCenterIcon } from "../../assets/img/ico-align-center.svg";
import { ReactComponent as AlignLeftIcon } from "../../assets/img/ico-align-left.svg";
import { ReactComponent as AlignRightIcon } from "../../assets/img/ico-align-right.svg";
import { ReactComponent as BoldIcon } from "../../assets/img/ico-text-bold.svg";
import { ReactComponent as ItalicIcon } from "../../assets/img/ico-text-italic.svg";
import { ReactComponent as UnderlineIcon } from "../../assets/img/ico-text-underline.svg";
import { toggle } from "../../helpers/utils";
import {
    getCurrentStyles, redo, setActiveIndex, setCurrentStyles, setCurrentTemplateImage, undo
} from "../../redux/slices/editorSlice";
import { setSeminarFormData } from "../../redux/slices/planningSlice";
import BackgroundEditor from "./parts/BackgroundEditor";
import BoxShadowEditor from "./parts/BoxShadowEditor";
import OutlineEditor from "./parts/OutlineEditor";
import RangeSlider from "./parts/RangeSlider";
import Template from "./templates/Template";
import { templates } from "./templates/variants";
import { useWindowSize } from "@uidotdev/usehooks";
import classNames from "classnames";

const generateFontSizes = (start, end, step) => {
    const sizes = [];

    for (let size = start; size <= end; size += step) {
        sizes.push({
            label: size,
            value: size
        });
    }

    return sizes;
};

const TemplateEditor = ({ onSubmit }) => {
    const { width } = useWindowSize();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const { activeIndex, currentTemplate, history, historyIndex } = useSelector((state) => state.editor);
    const elementRef = useRef();
    const seminarFormData = useSelector((state) => state.planning.seminarFormData);
    const navigate = useNavigate();
    const [isApplying, setIsApplying] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const [selectedTemplate, setSelectedTemplate] = useState(currentTemplate);
    const [activeSidebar, setActiveSidebar] = useState("templates"); // edit-text
    const currentStyles = useSelector(getCurrentStyles);
    const [zoom, setZoom] = useState(100);
    const [fonts, setFonts] = useState([]);
    const [zoomCoef, setZoomCoef] = useState(0);
    const [isImageReposition, setIsImageReposition] = useState(false);

    const tools = [
        {
            value: "700",
            name: "fontWeight",
            icon: <BoldIcon />
        },
        {
            value: "italic",
            name: "fontStyle",
            icon: <ItalicIcon />
        },
        {
            value: "underline",
            name: "textDecoration",
            icon: <UnderlineIcon />
        },
        {
            value: "left",
            name: "textAlign",
            icon: <AlignLeftIcon />
        },
        {
            value: "center",
            name: "textAlign",
            icon: <AlignCenterIcon />
        },
        {
            value: "right",
            name: "textAlign",
            icon: <AlignRightIcon />
        }
    ];
    const ranges = [
        {
            label: "Letter Spacing",
            name: "letterSpacing",
            step: 0.1,
            min: -5,
            max: 5,
            postfix: "px",
            defaultValue: 0
        }, {
            label: "Line Height",
            name: "lineHeight",
            step: 1,
            min: 0,
            max: 200,
            postfix: "px",
            defaultValue: 34
        }, {
            label: "Opacity",
            name: "opacity",
            step: 0.1,
            min: 0,
            max: 1,
            defaultValue: 1
        }
    ];
    const fontSizes = generateFontSizes(1, 400, 1);

    const handleChange = (name, value) => {
        dispatch(setCurrentStyles({
            name,
            value
        }));
    };

    const handleUndo = () => {
        dispatch(undo());
    };

    const handleRedo = () => {
        dispatch(redo());
    };

    const handleBlur = (e) => {

        // e.stopPropagation();
        // dispatch(setActiveIndex(null))
        // setActiveSidebar("templates")
        // document.activeElement.blur() 
    };

    const handleAdjustImage = () => {
        setIsImageReposition(!isImageReposition);
    }


    const canUndo = history.length > 1 && historyIndex > 0;
    const canRedo = history.length > 1 && historyIndex < history.length - 1;


    const htmlToImageConvert = async () => {
        setIsImageReposition(false);
        setIsApplying(true);
        dispatch(setActiveIndex(null));
        setActiveSidebar("templates");
        document.activeElement.blur();
        const fontEmbedCSS = await getFontEmbedCSS(elementRef.current);

        elementRef.current.style.transform = "scale(1)"
        toPng(elementRef.current, {
            fontEmbedCSS,
            pixelRatio: 3,
            quality: 1
        })
            .then(async (dataUrl) => {
                const response = await fetch(dataUrl);
                const blob = await response.blob();
                const file = new File([blob], Date.now().toString() + "image.png", { type: "image/png" });
                if (onSubmit) {
                    onSubmit(file);
                    return
                }

                dispatch(setSeminarFormData({
                    name: "photos",
                    value: [
                        ...seminarFormData?.photos?.length
                            ? seminarFormData.photos
                            : [], file
                    ]
                }));

                dispatch(setSeminarFormData({
                    name: "coverFilename",
                    value: file.name
                }));
                dispatch(setSeminarFormData({
                    name: "coverImageName",
                    value: file.name
                }));
                navigate(location.state?.previousLocation || -1, { state: { previousLocation: location.pathname } });
            })
            .catch((err) => {
                console.log("Error converting HTML to PNG:", err);
            })
            .finally(() => {
                setIsApplying(false);
            });
    };
    const [searchParams, setSearchParams] = useSearchParams()
    const handleBack = () => {
        if (onSubmit) {
            setSearchParams(prev => {
                const copy = new URLSearchParams(prev);
                if (copy.has('editor')) {
                    copy.delete('editor');
                }
                return copy;
            });
        } else {
            navigate(location.state?.previousLocation || -1, { state: { previousLocation: location.pathname } });
        }
    };

    useEffect(() => {
        const getFonts = async () => {
            const fontList = [];

            if (document.fonts && document.fonts.status === "loaded") {
                const fontFaces = document.fonts;

                for (const fontFace of fontFaces) {
                    fontList.push(fontFace.family);
                }
            }

            const fontsOptions = [...new Set(fontList)].map((name) => ({
                label: name,
                value: `'${name}', sans-serif`
            }));

            setFonts(fontsOptions);
        };

        getFonts();
    }, [document.fonts.status]);
    useEffect(() => {
        if (isSidebarOpen) {
            document.querySelector("body").classList.add("sidebar--close");
        } else {
            document.querySelector("body").classList.remove("sidebar--close");
        }

        return () => {
            document.querySelector("body").classList.remove("sidebar--close");
        };
    }, [isSidebarOpen]);

    const resize = () => {
        const edge = window.innerHeight > window.innerWidth ? window.innerWidth : window.innerHeight

        if (width < 700) {
            const k = (800 - edge) / 1000;

            setZoomCoef(k);
        }
    };

    useEffect(() => {
        // window.addEventListener("resize", resize)
        // return () => window.removeEventListener("resize", resize) 
        resize();
    }, [width]);

    const handleChangeImage = (e) => {
        const image = e.target.files[0];

        if (image) {
            dispatch(setCurrentTemplateImage(URL.createObjectURL(image)));
        }
    };

    return (
        <>
            <HeaderTemplateEditor
                handleBack={handleBack}
                isApplying={isApplying}
                onSubmit={htmlToImageConvert}
            />

            <div className="wrapper header-fixed">
                <main className="content editor-page">
                    <div className="overlay sidebar-btn-js" />

                    <aside
                        className={`editor-sidebar ${activeSidebar === "templates"
                            ? "--active"
                            : ""}`}
                    >
                        <div className="editor-sidebar--container">
                            <button
                                className="btn btn--square btn-sidebar-toggler sidebar-btn-js"
                                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                                type="button"
                            >
                                <span className="ico ico-sidebar-toggler">
                                    <CaretLeftIcon />
                                </span>
                            </button>

                            <div className="editor-sidebar--header --has-border">
                                <div className="es--h-item">
                                    <p className="editor-sidebar--title">
                                        Cover Templates
                                    </p>
                                </div>
                            </div>

                            <div className="editor-sidebar--body --has-border">
                                <div className="es--body-item">
                                    <label className="mb-14">
                                        <input
                                            accept={"image/*"}
                                            className="hidden"
                                            hidden
                                            onChange={handleChangeImage}
                                            type="file"
                                        />

                                        <span className="btn btn btn--primary btn--md w-full">
                                            <span className="ico">
                                                <svg
                                                    fill="none"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    width="20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M12.9643 9.25885L9.99972 12.2234L7.03516 9.25885"
                                                        stroke="#02020A"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.4"
                                                    />

                                                    <path
                                                        d="M9.99985 3.33057V12.2234"
                                                        stroke="#02020A"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />

                                                    <path
                                                        d="M16.6695 13.7048C16.6695 15.3421 15.3422 16.6693 13.7049 16.6693H6.29513C4.65785 16.6693 3.33057 15.3421 3.33057 13.7048"
                                                        stroke="#02020A"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />
                                                </svg>
                                            </span>

                                            <span className="info">
                                                Replace Photo
                                            </span>
                                        </span>
                                    </label>
                                    <button
                                        className="btn btn--default btn--md w-full mb-14"
                                        onClick={handleAdjustImage}
                                    >
                                        <span className="ico">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" preserveAspectRatio="xMidYMid" width="16" height="16" viewBox="0 0 31.906 31.906">
                                                <path d="M31.567,16.699 L27.622,20.644 C27.427,20.838 27.172,20.936 26.917,20.936 C26.662,20.936 26.407,20.838 26.212,20.644 C25.822,20.254 25.822,19.623 26.212,19.234 L28.539,16.906 L16.945,16.906 L16.945,28.502 L19.234,26.214 C19.623,25.824 20.254,25.824 20.644,26.214 C21.033,26.603 21.033,27.234 20.644,27.624 L16.655,31.612 C16.624,31.644 16.583,31.657 16.548,31.683 C16.479,31.736 16.413,31.793 16.332,31.827 C16.210,31.877 16.080,31.904 15.950,31.904 C15.950,31.904 15.950,31.904 15.949,31.904 C15.949,31.904 15.949,31.904 15.948,31.904 C15.936,31.904 15.927,31.898 15.915,31.897 C15.672,31.889 15.431,31.798 15.245,31.612 L11.257,27.624 C10.867,27.234 10.867,26.603 11.257,26.214 C11.646,25.824 12.277,25.824 12.667,26.214 L15.000,28.547 L15.000,16.906 L3.358,16.906 L5.687,19.234 C6.076,19.623 6.076,20.254 5.687,20.644 C5.493,20.838 5.237,20.936 4.982,20.936 C4.727,20.936 4.471,20.838 4.277,20.644 L0.286,16.655 C0.099,16.468 -0.006,16.215 -0.006,15.950 C-0.006,15.817 0.021,15.687 0.072,15.566 C0.122,15.445 0.196,15.335 0.287,15.244 L4.277,11.257 C4.666,10.867 5.298,10.867 5.687,11.257 C6.076,11.646 6.076,12.277 5.687,12.667 L3.399,14.953 L15.000,14.953 L15.000,3.351 L12.665,5.687 C12.275,6.076 11.644,6.076 11.255,5.687 C10.865,5.297 10.865,4.666 11.255,4.277 L15.225,0.306 C15.394,0.115 15.642,-0.003 15.947,-0.004 C16.077,-0.004 16.207,0.022 16.329,0.073 C16.452,0.123 16.562,0.197 16.654,0.289 L20.642,4.277 C21.031,4.666 21.031,5.297 20.642,5.687 C20.447,5.881 20.192,5.979 19.937,5.979 C19.682,5.979 19.427,5.881 19.232,5.687 L16.945,3.400 L16.945,14.953 L28.498,14.953 L26.212,12.667 C25.822,12.277 25.822,11.646 26.212,11.257 C26.601,10.867 27.232,10.867 27.622,11.257 L31.609,15.244 C31.701,15.336 31.775,15.447 31.826,15.569 C31.876,15.691 31.902,15.821 31.902,15.951 C31.902,16.270 31.773,16.528 31.567,16.699 Z" />
                                            </svg>
                                        </span>
                                        <span className="info">
                                            {isImageReposition ? "Cancel" : "Adjust Photo"}
                                        </span>
                                    </button>
                                    <div
                                        className="row"
                                        style={{ gap: "8px 0" }}
                                    >
                                        {
                                            templates.map((template, index) => (
                                                <div
                                                    className="col-12 flex justify-center"
                                                    key={index}
                                                    style={{
                                                        aspectRatio: "1.32244897959",
                                                        overflow: "hidden"
                                                    }}
                                                >
                                                    <label
                                                        className="editor-checkbox"
                                                        style={{ transform: `scale(${0.34})` }}
                                                    >
                                                        <input
                                                            checked={selectedTemplate.templateId === template.templateId}
                                                            hidden
                                                            name="template-select"
                                                            onChange={(e) => setSelectedTemplate(template)}
                                                            type="radio"
                                                            value={template.templateId}
                                                        />

                                                        <span className="ico ico-checked">
                                                            <CheckIcon />
                                                        </span>

                                                        <div className="editor-checkbox--overlay" />

                                                        <Template template={template} />
                                                    </label>
                                                </div>
                                            )



                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aside>

                    <aside
                        className={`editor-sidebar ${activeSidebar === "edit-text"
                            ? "--active"
                            : ""}`}
                    >
                        <div className="editor-sidebar--container">
                            <button
                                className="btn btn--square btn-sidebar-toggler sidebar-btn-js"
                                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                                type="button"
                            >
                                <span className="ico ico-sidebar-toggler">
                                    <CaretLeftIcon />
                                </span>
                            </button>

                            <div className="editor-sidebar--header --has-border">
                                <div className="es--h-item">
                                    <button
                                        className="btn"
                                        onClick={() => setActiveSidebar("templates")}
                                        type="button"
                                    >
                                        <span
                                            className="ico"
                                            style={{ "--ico-size": "18px" }}
                                        >
                                            <CaretLeftIcon />
                                        </span>
                                    </button>

                                    <p className="editor-sidebar--title">
                                        Edit Text
                                    </p>
                                </div>
                            </div>

                            <div className="editor-sidebar--body --has-border">
                                <div className="es--body-item">
                                    <label className="mb-14">
                                        <input
                                            accept={"image/*"}
                                            className="hidden"
                                            hidden
                                            onChange={handleChangeImage}
                                            type="file"
                                        />

                                        <span className="btn btn btn--primary btn--md w-full">
                                            <span className="ico">
                                                <svg
                                                    fill="none"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    width="20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M12.9643 9.25885L9.99972 12.2234L7.03516 9.25885"
                                                        stroke="#02020A"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.4"
                                                    />

                                                    <path
                                                        d="M9.99985 3.33057V12.2234"
                                                        stroke="#02020A"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />

                                                    <path
                                                        d="M16.6695 13.7048C16.6695 15.3421 15.3422 16.6693 13.7049 16.6693H6.29513C4.65785 16.6693 3.33057 15.3421 3.33057 13.7048"
                                                        stroke="#02020A"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />
                                                </svg>
                                            </span>

                                            <span className="info">
                                                Replace Photo
                                            </span>
                                        </span>
                                    </label>
                                    <button
                                        className="btn btn--default btn--md w-full mb-14"
                                        onClick={handleAdjustImage}
                                    >
                                        <span className="ico">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" preserveAspectRatio="xMidYMid" width="16" height="16" viewBox="0 0 31.906 31.906">
                                                <path d="M31.567,16.699 L27.622,20.644 C27.427,20.838 27.172,20.936 26.917,20.936 C26.662,20.936 26.407,20.838 26.212,20.644 C25.822,20.254 25.822,19.623 26.212,19.234 L28.539,16.906 L16.945,16.906 L16.945,28.502 L19.234,26.214 C19.623,25.824 20.254,25.824 20.644,26.214 C21.033,26.603 21.033,27.234 20.644,27.624 L16.655,31.612 C16.624,31.644 16.583,31.657 16.548,31.683 C16.479,31.736 16.413,31.793 16.332,31.827 C16.210,31.877 16.080,31.904 15.950,31.904 C15.950,31.904 15.950,31.904 15.949,31.904 C15.949,31.904 15.949,31.904 15.948,31.904 C15.936,31.904 15.927,31.898 15.915,31.897 C15.672,31.889 15.431,31.798 15.245,31.612 L11.257,27.624 C10.867,27.234 10.867,26.603 11.257,26.214 C11.646,25.824 12.277,25.824 12.667,26.214 L15.000,28.547 L15.000,16.906 L3.358,16.906 L5.687,19.234 C6.076,19.623 6.076,20.254 5.687,20.644 C5.493,20.838 5.237,20.936 4.982,20.936 C4.727,20.936 4.471,20.838 4.277,20.644 L0.286,16.655 C0.099,16.468 -0.006,16.215 -0.006,15.950 C-0.006,15.817 0.021,15.687 0.072,15.566 C0.122,15.445 0.196,15.335 0.287,15.244 L4.277,11.257 C4.666,10.867 5.298,10.867 5.687,11.257 C6.076,11.646 6.076,12.277 5.687,12.667 L3.399,14.953 L15.000,14.953 L15.000,3.351 L12.665,5.687 C12.275,6.076 11.644,6.076 11.255,5.687 C10.865,5.297 10.865,4.666 11.255,4.277 L15.225,0.306 C15.394,0.115 15.642,-0.003 15.947,-0.004 C16.077,-0.004 16.207,0.022 16.329,0.073 C16.452,0.123 16.562,0.197 16.654,0.289 L20.642,4.277 C21.031,4.666 21.031,5.297 20.642,5.687 C20.447,5.881 20.192,5.979 19.937,5.979 C19.682,5.979 19.427,5.881 19.232,5.687 L16.945,3.400 L16.945,14.953 L28.498,14.953 L26.212,12.667 C25.822,12.277 25.822,11.646 26.212,11.257 C26.601,10.867 27.232,10.867 27.622,11.257 L31.609,15.244 C31.701,15.336 31.775,15.447 31.826,15.569 C31.876,15.691 31.902,15.821 31.902,15.951 C31.902,16.270 31.773,16.528 31.567,16.699 Z" />
                                            </svg>
                                        </span>
                                        <span className="info">
                                            {isImageReposition ? "Cancel" : "Adjust Photo"}
                                        </span>
                                    </button>
                                    <div className="row gutters-form-7">
                                        <div className="col-12">
                                            <SelectOne
                                                label="Font"
                                                onChange={(option) => handleChange("fontFamily", option.value)}
                                                options={fonts}
                                                value={currentStyles?.fontFamily}
                                                wrapperClasses="select--outline select--outline-bg select--outline-2 input--sm flex-auto"
                                            />
                                        </div>

                                        <div className="col-12">
                                            <div className="flex gap-12">
                                                <SelectOne
                                                    onChange={(option) => handleChange("fontSize", option.value)}
                                                    options={fontSizes}
                                                    value={parseInt(currentStyles?.fontSize)}
                                                    wrapperClasses="select--outline select--outline-bg select--outline-2 input--sm flex-auto"
                                                />

                                                <label className="picker-custom btn btn--square btn--sm">
                                                    <input
                                                        onChange={({ target }) => handleChange("color", target.value)}
                                                        type="color"
                                                        value={currentStyles?.color || "#ffffff"}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="es--body-item px-0 pt-0">
                                    <div className="group-btn btn btn--md bg-light">
                                        {
                                            tools.map(({ icon, name, value }) => (
                                                <button
                                                    className={`btn btn--square btn-tool ${currentStyles?.[name] === value
                                                        ? "active"
                                                        : ""}`}
                                                    key={value}
                                                    onClick={(e) => handleChange(name, toggle(currentStyles?.[name], value))}
                                                    type="button"
                                                >
                                                    <span className="ico">
                                                        {icon}
                                                    </span>
                                                </button>
                                            )



                                            )
                                        }
                                    </div>
                                </div>

                                <div className="es--body-item">
                                    <div className="row gutters-form-7">
                                        {
                                            ranges.map((range) => (
                                                <div
                                                    className="col-12"
                                                    key={range.name}
                                                >
                                                    <RangeSlider
                                                        onChange={(value) => handleChange(range.name, value)}
                                                        value={currentStyles?.[range.name]}
                                                        {...range}
                                                    />
                                                </div>
                                            )



                                            )
                                        }
                                    </div>
                                </div>

                                <div className="es--body-item">
                                    <div className="row gutters-form-7">
                                        <div className="col-12">
                                            <BackgroundEditor
                                                currentStyles={currentStyles}
                                                handleChange={handleChange}
                                            />
                                        </div>

                                        <div className="col-12">
                                            <OutlineEditor
                                                currentStyles={currentStyles}
                                                handleChange={handleChange}
                                            />
                                        </div>

                                        <div className="col-12">
                                            <BoxShadowEditor
                                                currentStyles={currentStyles}
                                                handleChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aside>

                    <section
                        className="workscape-editor"
                        onClick={handleBlur}
                    >
                        <div className="workspace-body">
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setActiveSidebar("edit-text");
                                }}
                            >
                                <div
                                    ref={elementRef}
                                    style={{ transform: `scale(${zoom / 100 - zoomCoef})` }}
                                    className={classNames('template-editor', { 'image-reposition': isImageReposition })}
                                >
                                    <Template
                                        isEditable={true}
                                        template={selectedTemplate}
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            className="workspace-footer"
                            style={{ zIndex: 100 }}
                        >
                            <div className="workspace-footer-control">
                                <div
                                    className="calculator"
                                    data-calculator
                                >
                                    <button
                                        className="btn btn-calculator btn--square btn-calc--minus"
                                        onMouseDown={({ target }) => setZoom((prev) => +prev - 10)}
                                        type="button"
                                    >
                                        <span className="ico">
                                            <CalcMinusIcon />
                                        </span>
                                    </button>

                                    <input
                                        className="input"
                                        name="zoom"
                                        onChange={({ target }) => setZoom(target.value)}
                                        type="text"
                                        value={zoom}
                                    />

                                    <button
                                        className="btn btn-calculator btn--square btn-calc--plus"
                                        onMouseDown={({ target }) => setZoom((prev) => +prev + 10)}
                                        type="button"
                                    >
                                        <span className="ico">
                                            <CalcPlusIcon />
                                        </span>
                                    </button>
                                </div>

                                {
                                    zoom !== 100
                                        ? (
                                            <button
                                                type="button"
                                                className="btn btn-calculator btn--square ms-4 px-5                                                                                                                     "
                                                onMouseDown={({ target }) => setZoom(100)}
                                            >
                                                Reset
                                            </button>
                                        )


                                        : null
                                }
                            </div>

                            <div className="workspace-footer-control">
                                <button
                                    className={`btn btn--square btn-tool ${!canUndo
                                        ? "disabled"
                                        : ""}`}
                                    disabled={!canUndo}
                                    onClick={handleUndo}
                                    type="button"
                                >
                                    <span className="ico">
                                        <UndoIcon />
                                    </span>
                                </button>

                                <button
                                    className={`btn btn--square btn-tool ${!canRedo
                                        ? "disabled"
                                        : ""}`}
                                    disabled={!canRedo}
                                    onClick={handleRedo}
                                    type="button"
                                >
                                    <span className="ico">
                                        <RedoIcon />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </>
    );
};

export default TemplateEditor;
