import { belts, languages, sharedProfits, teachingStyles, trainingStyle } from "../../../constants";

export const teachingStyleOptions = teachingStyles.map(({ key, title }) => ({
    value: key,
    label: title
}));
export const specificBeltOptions = belts.filter(({ key }) => !["blue_belt", "white_belt"].includes(key)).map(({ key, title }) => ({
    value: key,
    label: title
}));

export const traningStyleOptions = trainingStyle.map(({ key, title }) => ({
    value: key,
    label: title
}));
export const languageOptions = languages.map(({ title, key }) => ({
    value: key,
    label: title
}));
export const sharedProfitOptions = sharedProfits.map(({ key, title }) => ({
    label: title,
    value: +key
}))
export const targetAudienceOptions = [
    {
        value: "any",
        label: "Everyone"
    }, {
        value: "female",
        label: "Female Only"
    }, {
        value: "male",
        label: "Male Only"
    }, {
        value: "kids",
        label: "Kids Only"
    }
]
