import * as yup from "yup";
import { transformToNumber } from "../../../helpers/utils";

export const tourDatesSchema = yup.object().shape({
    continent: yup.string().required().default("all"),
    countries: yup.array().of(yup.string()).nullable().default(null),
    startAt: yup.date().nullable().default(null),
    endAt: yup.date().nullable().default(null)
});

const priceRangeSchema = yup.object().shape({
    seminarMinPrice: yup.number().nullable().transform(transformToNumber)
        .test(
            'price-or-profit',
            'Either price range or shared profit must be provided',
            function (value) {
                const { sharedProfit } = this.parent;
                return sharedProfit || !!value;
            }
        ),
    seminarMaxPrice: yup.number().nullable().transform(transformToNumber)
        .test(
            'price-or-profit',
            'Either price range or shared profit must be provided',
            function (value) {
                const { sharedProfit } = this.parent;
                return sharedProfit || !!value;
            }
        ),
    sharedProfit: yup.number().nullable()
        .test(
            'price-or-profit',
            'Either price range or shared profit must be provided',
            function (value) {
                const { seminarMinPrice, seminarMaxPrice } = this.parent;
                return seminarMinPrice || seminarMaxPrice || !!value;
            }),
});

export const tourInformationSchema = yup.object().shape({
    ...priceRangeSchema.fields,
    targetAudience: yup.string().required().default("any"),
    languages: yup.array().of(yup.string()).required().default([]),
    traningStyle: yup.string().required().default(""),
    allowUnder18: yup.boolean().required().default(false),
    teachingStyles: yup.array().of(yup.string()).required().default([])
});

export const tourDesignSchema = yup.object().shape({
    photos: yup.array().required(),
    name: yup.string().required().default(""),
    summary: yup.string().required().default(""),
    isPrivate: yup.boolean().required().default(false),

});

export const tourSchema = yup.object().shape({
    ...tourDatesSchema.fields,
    ...tourInformationSchema.fields,
    ...tourDesignSchema.fields
});
