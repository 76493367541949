import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageLoader from "../../../components/ui/PageLoader";
import { USER_ROLE } from "../../../constants/enums";
import { getUser } from "../../../redux/slices/applicationSlice";
import { getSeminarFormData } from "../../../redux/slices/planningSlice";
import { getAcademyGeneralInfoFetch } from "../../../redux/thunks/academyGeneralInfoThunks";
import { getInstructorGeneralInfoFetch } from "../../../redux/thunks/applicationThunks";
import { getChatByIdFetch } from "../../../redux/thunks/chatsThunks";

const withCommonData = (WrappedComponent) => {
    return (props) => {
        const dispatch = useDispatch();
        const { chatId: paramsChatId } = useParams();
        const chatId = paramsChatId === "null" ? null : paramsChatId;
        const user = useSelector(getUser);
        const formData = useSelector(getSeminarFormData);
        const { generalInfo, isGeneralInfoLoading } = useSelector(state => state.application);
        const { currentChatData, isLoading: isChatDataLoading } = useSelector(state => state.chats);
        const isLoading = isGeneralInfoLoading || isChatDataLoading;
        const [isInCountry, setIsInCountry] = useState(false);

        useEffect(() => {
            if (!chatId) return;
            dispatch(getChatByIdFetch({ chatId }))
        }, [chatId]);

        useEffect(() => {

        }, [user, generalInfo]);

        useEffect(() => {
            if (user?.role === USER_ROLE.academy) {
                dispatch(getAcademyGeneralInfoFetch());
            }
            if (user?.role === USER_ROLE.instructor) {
                dispatch(getInstructorGeneralInfoFetch());
            }
        }, [user]);

        useEffect(() => {
            const users = currentChatData?.users;
            const instructorCountry = users?.find(user => user?.role === USER_ROLE.instructor)?.address?.country
            const academyCountry = currentChatData?.country
            setIsInCountry(instructorCountry === academyCountry)
        }, [currentChatData])

        if (!user) return;

        if (isLoading) {
            return <PageLoader />
        }
        return (
            <WrappedComponent
                {...props}
                chatId={chatId}
                user={user}
                formData={formData}
                generalInfo={generalInfo}
                isInCountry={isInCountry}
                currentChatData={currentChatData}
            />
        );
    };
};

export default withCommonData;
