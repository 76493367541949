import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PageLoader from '../../../components/ui/PageLoader'
import { getTourByIdFetch } from '../../../redux/thunks/toursThunks'
import PlanTourPage from './PlanTourPage'

const EditTourPage = () => {
    const dispatch = useDispatch()
    const { tour: tourDetails, isLoading: isTourLoading } = useSelector((state) => state.tours)
    const { tourId } = useParams()

    useEffect(() => {
        dispatch(getTourByIdFetch({ tourId }));
    }, [tourId])

    if (isTourLoading) {
        return <PageLoader />
    }

    if (!tourDetails) {
        return null
    }

    const prepareInitialData = (tourDetails) => {
        return {
            city: tourDetails.city,
            continent: tourDetails.continent,
            countries: tourDetails.countries || [],
            seminarMinPrice: tourDetails.seminarMinPrice,
            seminarMaxPrice: tourDetails.seminarMaxPrice,
            sharedProfit: tourDetails.sharedProfit,
            targetAudience: tourDetails.targetAudience,
            languages: tourDetails.languages,
            traningStyle: tourDetails.traningStyle,
            specificBelt: tourDetails.specificBelt,
            allowUnder18: tourDetails.allowUnder18,
            teachingStyles: tourDetails.teachingStyles,
            name: tourDetails.name,
            summary: tourDetails.summary,
            isPrivate: tourDetails.isPrivate,
            isAvailablePrivateLesson: tourDetails.isAvailablePrivateLesson,
            startAt: tourDetails.startAt,
            endAt: tourDetails.endAt,
            photos: tourDetails?.photos.map((photo) => ({
                id: photo?.id,
                url: photo?.file
            })),
        };
    }
    return (
        <PlanTourPage tourDetails={tourDetails} defaultValues={prepareInitialData(tourDetails)} />
    )
}

export default EditTourPage