import { useState } from 'react';
import { createPortal } from 'react-dom';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import CheckboxSwitch from '../../../../components/ui/formElements/CheckboxSwitch';
import Input from '../../../../components/ui/formElements/Input';
import Textarea from '../../../../components/ui/formElements/Textarea';
import TemplateEditor from '../../../editor/TemplateEditor';
import PopularQuestions from '../../../planning/parts/PopularQuestions';
import SeminarPhotosUpload from '../../../planning/parts/SeminarPhotosUpload';
import { PLANNING_STATUS } from '../../constants/emuns';

const SeminarDesignTab = ({
    planningStatus = PLANNING_STATUS.draft,
    useFormReturn,
    role,
    generalInfo,
    setPreview,
}) => {
    const { register, setValue, watch, resetField, getValues, formState: { errors }, control } = useFormReturn
    const [searchParams, setSearchParams] = useSearchParams();
    const isEditorOpen = searchParams.get("editor")

    const handleDeletePhoto = (id, cb) => {
        const deletePhotos = getValues("delete_photos");
        setValue("delete_photos", deletePhotos ? [...deletePhotos, id] : [id]);
        cb()
    };

    const handleChangeCoverImage = (file) => {
        setPreview(prev => ({
            ...prev,
            cover: file
        }));
        if (file?.id) {
            setValue("coverId", file?.id, { shouldDirty: true });
            return;
        }
        const coverFilename = file?.name || file?.originalname;
        setValue("coverFilename", coverFilename);
    };


    return (
        <>
            <Controller
                name="photos"
                control={control}
                render={({ field: { onChange, value, } }) => (
                    <SeminarPhotosUpload
                        error={errors.photos?.message}
                        onDelete={(id) => handleDeletePhoto(id, () => onChange(photos))}
                        setCoverImage={(file) => handleChangeCoverImage(file)}
                        setImages={onChange}
                        images={value}
                        isNew
                    />
                )}
            />

            <hr className="hr mb-42" />

            <h6 className="heading mb-32">
                Seminar Info
            </h6>

            <div className="row gx-16 py-24 mb-42">
                <div className="col-12">
                    <Input
                        inputClasses="input--solid"
                        wrapperClasses="input--lg"
                        error={errors.seminarName?.message}
                        label="Seminar Name"
                        {...register("seminarName")}
                        placeholder="Add a name here..."
                    />
                </div>

                <div className="col-12">
                    <Textarea
                        wrapperClasses="input--lg"
                        textareaClasses="input--solid"
                        error={errors.summary?.message}
                        label="Summary"
                        {...register("summary")}
                        placeholder="Please write summary here..."
                        style={{ minHeight: 140 }}
                    />
                </div>

                {
                    planningStatus !== PLANNING_STATUS.draft && (
                        <>
                            <hr className="hr" />

                            <Controller
                                name="faq"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <PopularQuestions
                                        data={value}
                                        errors={errors.faq}
                                        handleChange={onChange}
                                        isErrorsShown={errors.faq}
                                        isSeminar
                                        eventType="seminar"
                                    />
                                )}
                            />
                        </>
                    )}
                <Controller
                    name="isPrivate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <CheckboxSwitch
                            checked={value}
                            onChange={onChange}
                            text="Set this seminar as private."
                        />
                    )}
                />
            </div>
            {isEditorOpen && (
                <Portal>
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1000,
                        background: 'white'
                    }}>
                        <Controller
                            name="photos"
                            control={control}
                            render={({ field: { onChange, value = [] } }) => (
                                <TemplateEditor
                                    onSubmit={(file) => {
                                        onChange([...value, file]);
                                        setSearchParams(prev => {
                                            const copy = new URLSearchParams(prev);
                                            if (copy.has('editor')) {
                                                copy.delete('editor');
                                            }
                                            return copy;
                                        });
                                    }}
                                />
                            )}
                        />
                    </div>
                </Portal>
            )}
        </>
    )
}


function Portal({ children }) {
    const portalRoot = document.getElementById('portal-root') || createPortalRoot();

    return createPortal(children, portalRoot);
};

const createPortalRoot = () => {
    const div = document.createElement('div');
    div.id = 'portal-root';
    document.body.appendChild(div);
    return div;
};



export default SeminarDesignTab