import { Country } from "country-state-city";
import {
    useEffect, useMemo, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Outlet, useLocation, useNavigate, useParams
} from "react-router-dom";
import { EVENT_STATUS, EVENT_TYPE, USER_ROLE } from "../../../constants/enums";
import { EventHelper } from "../../../helpers/EventHelper";
import { checkErrors, getFullLocation } from "../../../helpers/utils";
import { getGeneralInfo, getUser } from "../../../redux/slices/applicationSlice";
import {
    getSeminarFormData, resetSeminarFormData, setSeminarFormData
} from "../../../redux/slices/planningSlice";
import { getAcademyGeneralInfoFetch } from "../../../redux/thunks/academyGeneralInfoThunks";
import { getInstructorGeneralInfoFetch } from "../../../redux/thunks/applicationThunks";
import { getSingleCampFetch } from "../../../redux/thunks/planCampThuncks";
import { calculateMinAttendanceDeadline, getStartDate } from "../helpers";
import PlanningSteps from "../parts/PlanningSteps";
import SeminarCardPreview from "../parts/SeminarCardPreview";
import withCommonData from "../parts/withCommonData";

const academyFields = {
    maxGymCapacity: "",
    address: '',

}
const instructorFields = {
    city: '',
    continent: "all",
}

const getInitialData = (role) => {
    const commonFields = {
        seminarMinPrice: null,
        seminarMaxPrice: null,
        sharedProfit: null,
        targetAudience: "any",
        languages: [],
        minAttendance: null,
        country: "",
        traningStyle: "",
        specificBelt: "",
        minimumAttendance: "",
        minAttendanceDeadline: "",
        teachingStyles: [],
        photos: [],
        name: "",
        summary: "",
        isPrivate: false,
        allowPrivateLessons: 0,
        allowUnder18: 0,
        schedule: []
    };
    return {
        ...commonFields,
        ...(role === USER_ROLE.instructor ? instructorFields : {}),
        ...(role === USER_ROLE.academy ? academyFields : {}),
    };
};

const prepareInitialData = (campDetails) => {
    return {
        startAt: campDetails?.startAt,
        endAt: campDetails?.endAt,
        name: campDetails?.name,
        teachingStyles: campDetails.teachingStyles ?? [],
        summary: campDetails?.summary,
        photos: campDetails?.photos.map((photo) => ({
            id: photo?.id,
            url: photo?.url
        })),
        videos: campDetails?.videos,
        specificBelt: campDetails?.specificBelt,
        what_included: campDetails?.what_included?.length
            ? campDetails?.what_included?.map((item) => item.id)
            : [],
        schedule: campDetails?.schedules?.length
            ? campDetails?.schedules?.map((date) => {
                return date.map((event) => ({
                    date: event.date,
                    start: event?.start,
                    end: event?.end,
                    name: event?.name
                }));
            })
            : [],
        invited_instructors: campDetails?.instructors,
        traningStyle: campDetails?.traningStyle ?? "",
        maxGymCapacity: campDetails?.maxGymCapacity ?? "",
        seminarMinPrice: campDetails?.seminarMinPrice,
        seminarMaxPrice: campDetails?.seminarMaxPrice,
        faq: campDetails?.faq,
        hotelLink: campDetails?.hotelLink,
        instructorLocation: campDetails?.instructorLocation,
        coverImageId: campDetails?.photos?.find((photo) => photo?.isCover)?.id,
        allowPrivateLessons: campDetails?.allowPrivateLessons,
        allowUnder18: campDetails?.allowUnder18
            ? "1"
            : 0,
        minimalPriceTicket: campDetails?.minimalPriceTicket,
        instructorPrice: campDetails?.instructorPrice,
        country: campDetails?.country,
        targetAudience: campDetails.targetAudience,
        minAttendance: campDetails?.minAttendance,
        minAttendanceRequirement: campDetails?.minAttendanceRequirement,
        minAttendanceDeadline: campDetails?.minAttendanceDeadline,

    }
}

const PlanCampWrapper = ({
    type,
    chatId,
    user,
    formData,
    generalInfo,
    isInCountry,
    currentChatData
}) => {
    const { pathname } = useLocation();
    const { campId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { campDetails, isLoading } = useSelector((state) => state.planCamps);
    const [errors, setErrors] = useState({});
    const [errorConfig, setErrorConfig] = useState({});
    const [preview, setPreview] = useState({});

    const handlePreviewChange = (name, value) => {
        setPreview((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleChange = (name, value) => {
        dispatch(setSeminarFormData({
            name,
            value
        }));

        if (name === "startAt" || name === "endAt") {
            handlePreviewChange("availableDates", [
                {
                    date: name === "startAt"
                        ? value
                        : formData?.startAt,
                    startTime: "00:00",
                    endTime: "23:59"
                }, {
                    date: name === "endAt"
                        ? value
                        : formData?.endAt,
                    startTime: "00:00",
                    endTime: "23:59"
                }
            ]);
        }

        if (preview.hasOwnProperty(name)) {
            handlePreviewChange(name, value);
        }
    };

    // preview 
    useEffect(() => {
        const location = campDetails
            ? getFullLocation([Country.getCountryByCode(campDetails.country)?.name, campDetails.city])
            : getFullLocation([Country.getCountryByCode(generalInfo?.country)?.name, generalInfo.city, generalInfo?.addressLine]);

        const instructors = campDetails?.length
            ? campDetails.instructors.map((item) => ({
                id: item.user?.id,
                fullName: item.user?.instructorProfile?.fullName,
            }))
            : [];

        setPreview((prev) => ({
            ...prev,
            fullName: user?.profile?.name || user?.profile?.fullName || '',
            photo: user?.profile?.photo || '',
            location,
            availableDates: campDetails?.seminarDates,
            instructors,
            cover: campDetails?.photos?.find((photo) => photo?.isCover) || null
        }));
    }, [generalInfo, campDetails, user]);


    useEffect(() => {
        setErrors(checkErrors(formData, errorConfig));
    }, [formData, errorConfig]);

    useEffect(() => {
        if (!campId) return;

        dispatch(getSingleCampFetch(campId));
    }, [campId]);

    useEffect(() => {
        if (campId && campDetails && !isLoading) {
            if (location.state?.previousLocation !== "/template-editor") {
                const minAttendanceDeadline = campDetails?.minAttendanceDeadline || calculateMinAttendanceDeadline(campDetails?.startAt, isInCountry);
                dispatch(resetSeminarFormData(prepareInitialData({ ...campDetails, minAttendanceDeadline })));
            }
        }

        if (!campId && user) {
            if (location.state?.previousLocation !== "/template-editor") {
                if (!user?.role) return;
                setPreview({})
                dispatch(resetSeminarFormData(getInitialData(user.role)));
                navigate("/plan-camp");
            }
        }
    }, [campDetails, user, type]);

    const step = EventHelper.getEventRequest(campDetails, chatId)?.step || currentChatData?.academyAccept?.step

    return (
        <>
            <PlanningSteps
                step={step}
                isCreated={campId}
                isActive={campId && campDetails?.status === EVENT_STATUS.active}
                chatId={chatId}
                eventType={EVENT_TYPE.camp}

            />

            <section className="section-42">
                <div className="container">
                    <div className="section-row-block">
                        <div className="section-row-block__body">
                            <Outlet
                                context={[
                                    formData,
                                    handleChange,
                                    errors,
                                    handlePreviewChange,
                                    preview,
                                    setErrorConfig
                                ]}
                            />
                        </div>

                        {
                            !pathname.includes("success") && <SeminarCardPreview {...preview} />
                        }
                    </div>
                </div>
            </section>
        </>
    );
};

export default withCommonData(PlanCampWrapper);
