import { yupResolver } from '@hookform/resolvers/yup'
import { Country } from 'country-state-city'
import { format, isValid } from 'date-fns'
import { pick } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import api from '../../../api'
import Congratulations from '../../../components/parts/Congratulations'
import PageLoader from '../../../components/ui/PageLoader'
import { EVENT_STATUS, EVENT_STEP, USER_ROLE } from '../../../constants/enums'
import { convertObjectToFormData, getFullLocation } from '../../../helpers/utils'
import { useTabs } from '../../../hooks/useTabs'
import { createCampFetch, updateCampFetch } from '../../../redux/thunks/planCampThuncks'
import SeminarCardPreview from '../../planning/parts/SeminarCardPreview'
import TermsTab from '../common/TermsTab'
import FooterActions from '../components/FooterActions'
import TabsNavigation from '../components/TabsNavigation'
import withCommonData from '../components/withCommonData'
import { PLANNING_STATUS } from '../constants/emuns'
import { campDatesSchema, campInformationSchema, campSchema, termsSchema } from '../validation/campSchema'
import CampDateTab from './tabs/CampDateTab'
import CampDesignTab from './tabs/CampDesignTab'
import CampInformationTab from './tabs/CampInformationTab'


const PlanCampPage = ({
    chatId,
    user,
    generalInfo,
    isInCountry,
    currentChatData,
    campDetails,
    defaultValues
}) => {
    const { campId, tourId, requestId } = useParams();
    const [searchParams] = useSearchParams();
    const editStep = searchParams.get("edit");

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isCreated, setIsCreated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const planningStatus = (() => {
        if (campDetails?.status === EVENT_STATUS.active) {
            return PLANNING_STATUS.active;
        }
        return chatId ? PLANNING_STATUS.negotiation : PLANNING_STATUS.draft;
    })();
    const currentStep = campDetails?.status === EVENT_STATUS.active ? EVENT_STEP.design : currentChatData?.academyAccept?.step

    const tabItems = [
        {
            label: "Time & Date",
            id: EVENT_STEP.date,
            editableStatus: [PLANNING_STATUS.draft, PLANNING_STATUS.negotiation],
            schema: campDatesSchema(user.role, planningStatus)
        },
        {
            label: `Seminar Information`,
            id: EVENT_STEP.details,
            editableStatus: [PLANNING_STATUS.draft, PLANNING_STATUS.negotiation],
            schema: campInformationSchema(user.role, planningStatus)
        },
        {
            label: "Design & Text",
            id: EVENT_STEP.design,
            editableStatus: [PLANNING_STATUS.draft, PLANNING_STATUS.negotiation, PLANNING_STATUS.active],
            schema: campSchema(user.role, planningStatus)
        },
        {
            label: "Booking Specific Terms",
            id: EVENT_STEP.terms,
            editableStatus: [PLANNING_STATUS.negotiation],
            schema: termsSchema
        },
    ];

    const filteredTabs = tabItems.filter(({ editableStatus }) => editableStatus.includes(planningStatus))
    const { activeTab, setActiveTab, activeTabIndex } = useTabs(filteredTabs, 't');
    const [previewData, setPreviewData] = useState({});

    // Set active tab based on chat data if it exists
    useEffect(() => {
        if (editStep) {
            setActiveTab(editStep)
            return;
        }
        if (currentStep) {
            setActiveTab(currentStep)
        } else {
            setActiveTab(EVENT_STEP.date)
        }
    }, [currentChatData, setActiveTab])

    const currentSchema = planningStatus === PLANNING_STATUS.draft && activeTabIndex === filteredTabs.length - 1 ?
        campSchema(user.role, planningStatus) : filteredTabs[activeTabIndex]?.schema;

    const resolver = yupResolver(currentSchema);
    const useFormReturn = useForm({
        resolver,
        defaultValues: {
            isPrivate: false,
            allowUnder18: 0,
            ...defaultValues
        }
    })
    const { handleSubmit, watch, formState: { errors, touchedFields, dirtyFields } } = useFormReturn
    const editedFields = Object.keys(dirtyFields).filter(key => dirtyFields[key] && errors[key] === undefined)

    watch((values, options) => {
        if (['coverImage', 'name', 'traningStyle', 'schedule'].includes(options.name)) {
            setPreviewData((prev) => ({
                ...prev,
                [options.name]: values[options.name],
                cover: options.name === 'coverImage' ? values[options.name] : prev.cover,
                availableDates: options.name === 'schedule' ? values[options.name] : prev.availableDates
            }))
        }
    })

    useEffect(() => {
        const location = campDetails
            ? getFullLocation([Country.getCountryByCode(campDetails.country)?.name, campDetails.city])
            : getFullLocation([Country.getCountryByCode(generalInfo?.country)?.name, generalInfo?.city, generalInfo?.addressLine]);

        const instructors = campDetails?.invited_instructors?.length
            ? campDetails.invited_instructors.map((item) => ({
                id: item.user?.id,
                fullName: item.user?.instructorProfile?.fullName,
            }))
            : [];

        setPreviewData((prev) => ({
            ...prev,
            fullName: user?.profile?.name || user?.profile?.fullName || '',
            seminarName: campDetails?.seminarName || '',
            photo: user?.profile?.photo || '',
            location,
            instructors: instructors,
            availableDates: campDetails?.schedule,
            cover: campDetails?.cover || null,

        }));
    }, [generalInfo, campDetails, user])

    useEffect(() => {
        console.log(errors, 'errors');
    }, [errors])

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const errorMessages = Object.keys(errors).map(key => errors[key].message)
            errorMessages.map(message => toast.warn(message))
        }
    }, [errors])



    const tabComponents = {
        [EVENT_STEP.date]: (
            <CampDateTab
                useFormReturn={useFormReturn}
                role={user?.role}
                generalInfo={generalInfo}
                planningStatus={planningStatus}
            />
        ),
        [EVENT_STEP.details]: (
            <CampInformationTab
                useFormReturn={useFormReturn}
                role={user?.role}
                planningStatus={planningStatus}
                preview={previewData}
                setPreview={setPreviewData}
            />
        ),
        [EVENT_STEP.design]: (
            <CampDesignTab
                planningStatus={planningStatus}
                useFormReturn={useFormReturn}
                role={user?.role}
                setPreview={setPreviewData}
                isInCountry={isInCountry}

            />
        ),
        [EVENT_STEP.terms]: (
            <TermsTab
                planningStatus={planningStatus}
                useFormReturn={useFormReturn}
                role={user?.role}
            />
        ),
    }

    const renderTab = (activeTab) => {
        return tabComponents[activeTab]
    }

    const transformData = (data) => {
        const result = { ...data }

        const startAt = new Date(result.startAt)
        const endAt = new Date(result.endAt)
        if (isValid(startAt) && isValid(endAt)) {
            result.startAt = format(startAt, "yyyy-MM-dd")
            result.endAt = format(endAt, "yyyy-MM-dd")
        }
        if (Array.isArray(result?.schedule)) {
            result.schedule = result.schedule.map(dayEvents =>
                dayEvents.map(item => ({
                    ...item,
                    date: (item?.date && isValid(new Date(item.date))) ? format(item.date, 'yyyy-MM-dd') : null
                }))
            );
        }
        return result
    }

    const update = async (data) => {
        const dataToUpdate = pick(data, editedFields);
        const body = convertObjectToFormData(dataToUpdate, new FormData(), false);
        const res = await dispatch(updateCampFetch({
            campId,
            body
        })).unwrap();
        if (res) {
            if (chatId) {
                navigate(`/chat/${chatId}`);
            } else {
                navigate(`/my-seminars/camps/${campId}`);
                toast.success("Successfully updated")
            }
        }
    }

    const create = async (data) => {
        const body = convertObjectToFormData(transformData(data));
        const res = await dispatch(createCampFetch(body)).unwrap();
        if (res) {
            setIsCreated(true);
        }
    }

    const updateTerms = async (terms) => {
        const res = await api.requests.createUpdateTerms({
            requestId,
            body: { terms }
        });

        if (res?.success) {
            navigate(`/chat/${chatId}`);
        } else {
            toast.error(res?.message || "Something went wrong");
        }
    }

    const onSubmit = async (data) => {
        try {
            console.log("Form data:", data);
            setIsLoading(true);

            if (activeTab === EVENT_STEP.terms) {
                updateTerms(data.terms);
                return;
            }

            if (activeTabIndex < filteredTabs.length - 1) {
                if (planningStatus === PLANNING_STATUS.negotiation) {
                    await update(data);
                } else {
                    setActiveTab(filteredTabs[activeTabIndex + 1].id);
                }
            } else {
                console.log("Final Submit:", data);
                if (campId) {
                    await update(data);
                } else {
                    await create(data);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        if (chatId) {
            navigate(`/chat/${chatId}`);
            return;
        }
        if (activeTab === EVENT_STEP.date) {
            navigate("/");
        } else {
            const currentStepIndex = filteredTabs.findIndex((tab) => tab.id === activeTab);
            if (currentStepIndex > 0) {
                const previousStep = filteredTabs[currentStepIndex - 1];
                setActiveTab(previousStep.id);
            }
        }
    };

    if (isLoading) {
        return <PageLoader />
    }
    return (
        <>
            <TabsNavigation
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabItems={filteredTabs}
                currentStep={currentStep}
                planningStatus={planningStatus}
            />
            <section className="section-42">
                <div className="container">
                    {!isCreated && (
                        <div className="section-row-block plan-block">
                            <form onSubmit={handleSubmit(onSubmit)} className="section-row-block__body">
                                {renderTab(activeTab)}
                                <FooterActions
                                    onCancel={handleCancel}
                                    submitButtonText={(activeTabIndex === filteredTabs.length - 1 && planningStatus === PLANNING_STATUS.draft) ? "Publish" : (planningStatus !== PLANNING_STATUS.draft) || (activeTabIndex === filteredTabs.length - 1) ? "Apply Changes" : "Continue"}
                                    showSubmitIcon={(activeTabIndex !== filteredTabs.length - 1) || (planningStatus !== PLANNING_STATUS.draft)}
                                    chatId={chatId}
                                    isTerms={activeTab === EVENT_STEP.terms}
                                />
                            </form>
                            <SeminarCardPreview {...previewData} />
                        </div>
                    )}
                    {isCreated && (
                        <>
                            {user?.role === USER_ROLE.academy && (
                                <Congratulations
                                    text="Your pending camp has been created. Do you want invite insturctor(s) to your camp?."
                                    additionalLink={{
                                        to: "/instructors",
                                        title: "Invite Instructor(s)"
                                    }}
                                />
                            )}

                            {user?.role === "instructor" && (
                                <Congratulations text="Your pending camp has been created." />
                            )}
                        </>
                    )}

                </div>
            </section>
        </>
    )
}

export default withCommonData(PlanCampPage)