import React from "react";
import { useTranslation } from "react-i18next";
import findValue from "../../helpers/findValue";

const WhyChoseUs = ({ pageData }) => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'home' });
    const getValue = (key) => findValue(pageData?.blocks, key);
    const listData =  [
        {
            title: t('benefits_1_title'),
            description: t('benefits_1_descr')
        },
        {
            title: t('benefits_2_title'),
            description: t('benefits_2_descr')
        },
        {
            title: t('benefits_3_title'),
            description: t('benefits_3_descr')
        },
        {
            title: t('benefits_4_title'),
            description: t('benefits_4_descr')
        },
        {
            title: t('benefits_5_title'),
            description: t('benefits_5_descr')
        },
        {
            title: t('benefits_6_title'),
            description: t('benefits_6_descr')
        }
    ];

    return (
        <section className="section">
            <div className="container">
                <h2
                    className="heading text-center mb-80"
                >{ t('platform_benefits')}</h2>

                <div className="row gx-60">
                    {listData.map((item, index) => (
                        <div
                            className="col-12 col-md-6 col-xl-4 flex flex-col"
                            key={index}
                        >
                            <div className="choose-item">
                                <h4 className="heading mb-20">
                                    {item.title}
                                </h4>

                                <p className="choose-item__description ">
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    )



                    )}
                </div>

            </div>
        </section>
    );
};

export default WhyChoseUs;
