export const USER_ROLE = {
    academy: 'academy',
    instructor: 'instructor',
    student: 'student',
    guest: 'guest',
};

export const EVENT_TYPE = {
    seminar: "seminar",
    camp: "plancamp",
    tour: "tour",
    privateLesson: "privateLesson"
}

export const EVENT_STATUS = {
    pending: "pending",
    active: "active",
    canceled: "canceled"
}

export const EVENT_STEP = {
    date: "location_and_date",
    details: "details",
    design: "design",
    terms: "terms",
}

export const STEPS_ORDER = [
    EVENT_STEP.date,
    EVENT_STEP.details,
    EVENT_STEP.design,
    EVENT_STEP.terms,
]
