import { Country } from "country-state-city"
import { EVENT_TYPE } from "../constants/enums"
import { getDateRange, getDateRangeBetween, getDateRangeFromSchedule, getFullLocation, getTeachStyle, getTrainingStyle } from "./utils"

export class EventHelper {
    static getEventRequest(eventDetails, id, field = "chatId") {
        return eventDetails?._eventRequest?.academyRequests?.find(req => +req[field] === +id)
    }
    static eventTotalIncome(eventDetails) {
        return eventDetails?.minimalPriceTicket * eventDetails?.maxGymCapacity
    }
    static instructorCut(eventDetails) {
        if (eventDetails?.sharedProfit) {
            return this.eventTotalIncome(eventDetails) * (eventDetails?.sharedProfit / 100)
        } else {
            return eventDetails?.instructorPrice
        }
    }
    static academyCut(eventDetails) {
        return this.eventTotalIncome(eventDetails) - this.instructorCut(eventDetails)
    }
    static ticketsLeft(eventDetails) {
        const { maxGymCapacity = 0, ticketsPurchased = 0 } = eventDetails
        return maxGymCapacity - ticketsPurchased
    }
    static fee(eventDetails) {
        const { minimalPriceTicket = 0, _ticketFee = 0 } = eventDetails
        return minimalPriceTicket * _ticketFee
    }
    static totalTicketPrice(eventDetails) {
        return eventDetails?.minimalPriceTicket + this.fee(eventDetails)
    }
    static location(eventDetails) {
        const countries = eventDetails?.countries?.map(c => Country.getCountryByCode(c)?.name) || []
        console.log(eventDetails?.country, "eventDetails");
        
        return getFullLocation([
            ...((!countries?.length && (!eventDetails?.country || eventDetails?.country === "ALL")) ? [eventDetails?.continent] : []),
            ...countries,
            Country.getCountryByCode(eventDetails?.country)?.name,
            eventDetails?.city,
            eventDetails?.address
        ])
    }
    static priceRange(eventDetails) {
        return eventDetails?.sharedProfit
            ? `Academy: ${100 - eventDetails?.sharedProfit}%, Instructor: ${eventDetails?.sharedProfit}%`
            : "$" + [eventDetails?.seminarMinPrice, eventDetails?.seminarMaxPrice].filter(Boolean).join(" - $")
    }
    static dateRange(eventDetails) {
        const { seminarDates, schedules, startAt, endAt, _type } = eventDetails
        if (_type === EVENT_TYPE.camp) {
            return schedules?.length ? getDateRangeFromSchedule(schedules) : getDateRangeBetween(startAt, endAt)
        }
        if (!seminarDates?.length && !startAt && !endAt) return 'Dates are not specified'
        return seminarDates?.length ? getDateRange(eventDetails?.seminarDates) : getDateRangeBetween(startAt, endAt)
    }
    static style(eventDetails) {
        return getTrainingStyle(eventDetails?.traningStyle)?.[0]
    }
    static teaching(eventDetails) {
        return getTeachStyle(eventDetails?.teachingStyles)?.filter(Boolean).join(", ")
    }
}