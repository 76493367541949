import {
    useEffect, useState
} from "react";
import api from "../../api";
import Input from "../ui/formElements/Input";
import ModalTemplate from "./ModalTemplate";
import { toBase64 } from "../../helpers/utils";

export const EditCooperateTeam = ({ 
    open, 
    handleClose, 
    data, 
    onSuccess, 
    onRemove = () => {} 
}) => {
    const [name, setName] = useState("");
    const [logo, setLogo] = useState(null);
    const [formConfig, setImageConfig] = useState({
        imageChangeOnNew: false,
        nameChangeOnNew: false
    });
    const [imageTeam64, setImageTeam64] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (data.name) {
            setName(data.name);
        }
    }, []);

    const save = async (e) => {
        e.preventDefault();

        if (!name) {
            setErrors((prev) => ({
                ...prev,
                name: true
            }));

            return;
        }

        const form = new FormData();

        if (formConfig.imageChangeOnNew) {
            form.append("logo", logo);
        }

        form.append("name", name);
        setIsLoading(true);
        const res = await api.cooperateTeam.updateCooperateTeam(form, data.id);

        if (res.success) {
            onSuccess && onSuccess({
                action: "update",
                id: data.id,
                team: res.data
            });
            handleClose && handleClose();
        }

        setIsLoading(false);
    };

    const deleteTeam = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const res = await api.cooperateTeam.deleteCooperateTeam(data.id);

        if (res.success) {
            onSuccess && onSuccess({
                action: "delete",
                id: data.id
            });
            handleClose && handleClose();
        }

        onRemove()
        setIsLoading(false);
    };

    const changePhoto = async (file) => {
        if (!file) return;

        const res = await toBase64(file);

        setImageConfig((prev) => ({
            ...prev,
            imageChangeOnNew: true
        }));
        setLogo(file);
        setImageTeam64(res);
    };

    return (
        <ModalTemplate
            onClick={handleClose}
            open={open}
        >
            <div className="modal__body">
                <form className="form form--lg">
                    <div className="form-header">
                        <div className="heading-block">
                            <h3 className="heading">
                                Edit Team
                            </h3>

                            {/* <p className="text--md desk-heading">*/}
                            {/*    Select one of options below to create account*/}
                            {/* </p>*/}
                        </div>
                    </div>

                    <div className="form-body">
                        <div className="row gutters-form">

                            <div className="col-12 flex justify-center">
                                <label
                                    className="tw-item tw-logo cursor-pointer"
                                    htmlFor="team-img-edit"
                                >
                                    <div className="ico ico--lg rounded-full border-ico">
                                        {
                                            imageTeam64
                                                ? (
                                                    <img
                                                        src={imageTeam64
                                                            ? imageTeam64
                                                            : process.env.REACT_APP_API_URL + imageTeam64}
                                                        alt=""
                                                    />
                                                )


                                                : data?.logo
                                                    ? <img src={(process.env.REACT_APP_API_URL + data.logo)} />
                                                    : null
                                        }
                                    </div>

                                    <input
                                        disabled={isLoading}
                                        hidden
                                        id="team-img-edit"
                                        name="team-img-edit"
                                        onChange={(e) => changePhoto(e.target.files[0])}
                                        type="file"
                                    />
                                </label>
                            </div>

                            <div className="col-12">
                                <div className="add-widget-control">
                                    <Input
                                        disabled={isLoading}
                                        inputClasses="input--solid"
                                        label="Team Name"
                                        onInput={(e) => {
                                            setName(e.currentTarget.value);
                                            setErrors((prevState) => ({
                                                ...prevState,
                                                name: false
                                            }));
                                        }}
                                        placeholder="Name"
                                        value={name}
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                {errors.name && (
                                    <p className="error-input">
                                        Enter a team name
                                    </p>
                                )}
                            </div>

                        </div>
                    </div>

                    <div className="form-footer">

                        <div className="flex-group justify-between">
                            <button
                                className={`btn btn--danger btn--sm ${isLoading
                                    ? "disabled"
                                    : ""}`}
                                onClick={deleteTeam}
                            >
                                <span>
                                    Delete
                                </span>
                            </button>

                            <button
                                className={`btn btn--primary btn--sm ${isLoading
                                    ? "disabled"
                                    : ""}`}
                                onClick={save}
                            >
                                <span>
                                    Save
                                </span>
                            </button>
                        </div>
                    </div>

                </form>

            </div>

        </ModalTemplate>
    );
};
